import {
  Center,
  DrawerBody,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { messagesSelector } from "../../state/chat";
import { meSelector } from "../../state/peer";
import { useStore } from "../../state/store";
import { EmptyStreet, EmptyStreetDark } from "./images/EmptyStreet";
import { HappyBird, HappyBirdDark } from "./images/HappyBird";
import { Message } from "./Message";

export const ChatBody = ({ isOpen, isConnected }) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const messages = useStore(messagesSelector);
  const { name: userName } = useStore(meSelector);

  const isDark = useColorModeValue(false, true);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (bodyRef.current) {
          bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
        }
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [bodyRef, messages]);

  return (
    <DrawerBody ref={bodyRef}>
      {!isConnected ? (
        <Center height="100%" textAlign="center" userSelect="none">
          <Stack>
            {isDark ? (
              <EmptyStreetDark
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  height: "min-content",
                  filter: "opacity(0.86)",
                }}
              />
            ) : (
              <EmptyStreet
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  height: "min-content",
                }}
              />
            )}
            <Text paddingTop={6}>Connect with someone to chat</Text>
          </Stack>
        </Center>
      ) : messages.length ? (
        <Stack spacing={3}>
          {messages.map((message) => {
            const { id, name, value } = message;
            return (
              <Message key={id} userName={name} isMe={userName === name}>
                {value}
              </Message>
            );
          })}
        </Stack>
      ) : (
        <Center height="100%" textAlign="center" userSelect="none">
          <Stack>
            {isDark ? (
              <HappyBirdDark
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  height: "min-content",
                }}
              />
            ) : (
              <HappyBird
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  height: "min-content",
                }}
              />
            )}

            <Text paddingTop={6}>Chat is empty, send some message!</Text>
          </Stack>
        </Center>
      )}
    </DrawerBody>
  );
};
