import {
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { ArrowSVG } from "../../common/icons";
import { roomSelector } from "../../state/firestore";
import { connectionStatusSelector } from "../../state/peer";
import { useStore } from "../../state/store";

export const HandwritedQRMessage = () => {
  const handWritedText = useBreakpointValue({
    base: {
      right: "-10px",
      top: "-96px",
      transform: "rotate(-2deg)",
      width: "100%",
    },
    md: {
      right: "-100px",
      top: "-80px",
      transform: "rotate(10deg)",
      width: "185px",
    },
  });

  const arrowIcon = useBreakpointValue({
    base: {
      left: "296px",
      top: "-56px",
      transform: "rotate(-60deg)",
    },
    md: {
      top: "18px",
      transform: "rotate(-18deg)",
      right: "-28px",
    },
  });

  const color = useColorModeValue("gray.600", "gray.400");
  const room = useStore(roomSelector);
  const connectionStatus = useStore(connectionStatusSelector);

  const tapOrClick = isMobile ? "Tap" : "Click";

  return (
    <>
      <Text
        fontSize={"xl"}
        fontFamily={"Caveat"}
        position={"absolute"}
        color={color}
        opacity="0"
        animation="fade-in 0.36s ease-out 0.8s forwards"
        {...handWritedText}
      >
        {connectionStatus === "CONNECTED"
          ? `Start sharing! ${tapOrClick} the drop zone or drag 'n' drop to share`
          : room
          ? "Start scanning the QR code or introduce the ID down below"
          : `${tapOrClick} on QR to generate it! Or introduce the peer ID down below!`}
      </Text>
      <Icon
        as={ArrowSVG}
        color={color}
        w={70}
        position={"absolute"}
        opacity="0"
        animation="fade-in 0.36s ease-out 0.8s forwards"
        {...arrowIcon}
      />
    </>
  );
};
