import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { forwardRef } from "react";

export const RoundedIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    return (
      <IconButton
        ref={ref}
        rounded="full"
        {...props}
        _focus={{ outline: "none" }}
        _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
      />
    );
  }
);

RoundedIconButton.displayName = "RoundedIconButton";
