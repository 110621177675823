import { ImFileExcel } from "@react-icons/all-files/im/ImFileExcel";
import { ImFileMusic } from "@react-icons/all-files/im/ImFileMusic";
import { ImFileOpenoffice } from "@react-icons/all-files/im/ImFileOpenoffice";
import { ImFilePdf } from "@react-icons/all-files/im/ImFilePdf";
import { ImFilePicture } from "@react-icons/all-files/im/ImFilePicture";
import { ImFilePlay } from "@react-icons/all-files/im/ImFilePlay";
import { ImFileText2 } from "@react-icons/all-files/im/ImFileText2";
import { ImFileWord } from "@react-icons/all-files/im/ImFileWord";
import { ImFileZip } from "@react-icons/all-files/im/ImFileZip";

export const getFileTypeIcon = ({ type }) => {
  const splited = type.split("/");
  switch (splited[0]) {
    case "image":
      return <ImFilePicture fontSize="18px" />;
    case "text":
      return <ImFileText2 fontSize="18px" />;
    case "video":
      return <ImFilePlay fontSize="18px" />;
    case "audio":
      return <ImFileMusic fontSize="18px" />;
    case "application":
      if ("pdf".includes(splited[1])) {
        return <ImFilePdf fontSize="18px" />;
      }
      if (
        ["csv", "excel", "spreadsheetml"].some((str) =>
          splited[1].includes(str)
        )
      ) {
        return <ImFileExcel fontSize="18px" />;
      }

      if (
        ["word", "vnd.openxmlformats"].some((str) => splited[1].includes(str))
      ) {
        return <ImFileWord fontSize="18px" />;
      }

      if (["opendocument"].some((str) => splited[1].includes(str))) {
        return <ImFileOpenoffice fontSize="18px" />;
      }

      if (["zip", "rar", "7z"].some((str) => splited[1].includes(str))) {
        return <ImFileZip fontSize="18px" />;
      }

    default:
      return <ImFileText2 fontSize="18px" />;
  }
};

export const getColorFromStatus = (status) => {
  switch (status) {
    case "Downloading":
      return "telegram";
    case "Uploading":
      return "purple";
    case "Paused":
      return "orange";
    case "Received":
    case "Sent":
      return "green";
    case "Downloaded":
    case "Saved":
      return "gray";
    default:
      break;
  }
};
