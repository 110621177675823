import { Center, Container, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Peer from "simple-peer";
import { useConnectionController } from "../../hooks/useConnectionController";
import { useDataController } from "../../hooks/useDataController";
import { useGuestController } from "../../hooks/useGuestController";
import { useHostController } from "../../hooks/useHostController";
import { usePeerDebug } from "../../hooks/usePeerDebug";
import { hasFilesSelector } from "../../state/files";
import { connectionStatusSelector, meSelector } from "../../state/peer";
import { konamiSelector, useStore } from "../../state/store";
import { Card } from "../common/Card";
import { ConnectInput } from "../ConnectInput";
import { DropZone } from "../DropZone";
import { FileList } from "../files/FileList";
import { HandwritedQRMessage } from "../handwrited/HandwritedQRMessage";
import { Hero } from "../Hero";
import { QR } from "../QR";
import { RoomID } from "../RoomID";
import { SupportedBrowsers } from "../SupportedBrowsers";

export const Body = () => {
  const [isSupported, setIsSupported] = useState(true);

  const hasFiles = useStore(hasFilesSelector);
  const connectionStatus = useStore(connectionStatusSelector);
  const { id: myID, name: myName } = useStore(meSelector);
  const konami = useStore(konamiSelector);

  usePeerDebug();
  useDataController();
  useConnectionController();
  useGuestController();
  useHostController();

  useEffect(() => {
    if (Peer && !Peer.WEBRTC_SUPPORT) setIsSupported(false);
  }, []);

  return (
    <Center
      opacity="0"
      animation="fade-in-down 1.36s cubic-bezier(0.18, 0.89, 0.83, 1.02) 0.12s forwards"
    >
      <Container
        maxWidth="container.md"
        fontFamily={konami ? "custom" : undefined}
      >
        <Stack
          alignItems="center"
          spacing={isSupported ? 16 : 6}
          paddingBottom={10}
        >
          <Hero myName={myName} />
          {isSupported ? (
            <Card
              padding={6}
              position="relative"
              textAlign="center"
              align="center"
            >
              {connectionStatus === "CONNECTED" ? (
                <DropZone hasFiles={hasFiles} />
              ) : (
                <QR myID={myID} />
              )}
              <HandwritedQRMessage />
              <RoomID roomID={myID} hasFiles={hasFiles} />
            </Card>
          ) : (
            <Card padding={8} textAlign="center" align="center">
              <SupportedBrowsers />
            </Card>
          )}

          {connectionStatus === "CONNECTED" || !isSupported ? null : (
            <ConnectInput myID={myID} connectionStatus={connectionStatus} />
          )}
          {hasFiles ? <FileList /> : null}
        </Stack>
      </Container>
    </Center>
  );
};
