import { ToastPosition, useColorMode, useToast } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useEffect, useRef } from "react";
import {
  MessageAlert,
  MessageAlertProps,
} from "../components/alerts/MessageAlert";

export interface useMessageToastProps extends MessageAlertProps {
  id?: string;
  unique: boolean;
  position: ToastPosition;
  colorMode?: string;
  onClose?: () => void;
  onOpen?: () => void;
}

export const useMessageToast = ({
  id,
  name,
  message,
  position,
  unique,
  onOpen,
}: useMessageToastProps) => {
  const toast = useToast();

  const toastRef = useRef(null);
  const { colorMode } = useColorMode();
  const toastID = useRef(id ?? nanoid());

  useEffect(() => {
    toast.update(toastRef.current, {
      // eslint-disable-next-line react/display-name
      render: ({ onClose }) => (
        <MessageAlert
          name={name}
          message={message}
          onClose={onClose}
          onOpen={onOpen}
          colorMode={colorMode}
        />
      ),
    });
  }, [colorMode, message, name, onOpen, toast]);

  const showToast = ({ name, message }) => {
    if (unique && toast.isActive(toastID.current)) return;

    toastRef.current = toast({
      id: unique ? toastID.current : undefined,
      position,
      // eslint-disable-next-line react/display-name
      render: ({ onClose }) => (
        <MessageAlert
          name={name}
          message={message}
          onClose={onClose}
          onOpen={onOpen}
          colorMode={colorMode}
        />
      ),
    });
  };

  return showToast;
};
