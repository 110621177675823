import { useColorModeValue } from "@chakra-ui/react";
import QRCode from "react-qr-code";

export const ExampleQRCode = ({ isDisabled }) => {
  const colorEnabled = useColorModeValue("#1A202C", "black");
  const colorDisabled = useColorModeValue("#CBD5E0", "#2D3748");

  const bgEnabled = useColorModeValue("white", "#CBD5E0");
  const bgDisabled = useColorModeValue("white", "#4a5568");

  return (
    <QRCode
      fgColor={isDisabled ? colorDisabled : colorEnabled}
      bgColor={isDisabled ? bgDisabled : bgEnabled}
      value="Double click on me to generate and unique and single-use ID"
    />
  );
};
