import { Box, Center, Text, useColorModeValue } from "@chakra-ui/react";
import { useState } from "react";

export const ExampleDropZone = () => {
  return (
    <Box
      rounded="lg"
      marginTop={-20}
      textAlign="center"
      _focus={{ outline: "none" }}
      _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
    >
      <Box
        boxShadow="2xl"
        padding={1.5}
        rounded="lg"
        overflow="hidden"
        cursor="pointer"
        height={"256px"}
        width="256px"
        transition="height 0.4s ease"
        backgroundColor={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.400", "gray.500")}
        border="1px solid"
        borderColor={useColorModeValue("gray.100", "gray.700")}
      >
        <Center
          height="100%"
          padding={6}
          userSelect="none"
          border="2px dashed"
          borderColor={useColorModeValue("gray.300", "gray.600")}
          rounded="lg"
        >
          <Text>{`Drag 'n' drop some files or click to select`}</Text>
        </Center>
      </Box>
    </Box>
  );
};
