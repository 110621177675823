import { ToastPosition, useColorMode, useToast } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useEffect, useRef } from "react";
import { BaseAlert, BaseAlertProps } from "../components/alerts/BaseAlert";

export interface useBaseToastProps extends BaseAlertProps {
  id?: string;
  unique: boolean;
  position: ToastPosition;
}

export const useBaseToast = ({
  id,
  type,
  title,
  content,
  position,
  unique,
}: useBaseToastProps) => {
  const toast = useToast();

  const toastRef = useRef(null);
  const { colorMode } = useColorMode();
  const toastID = useRef(id ?? nanoid());

  useEffect(() => {
    toast.update(toastRef.current, {
      // eslint-disable-next-line react/display-name
      render: ({ onClose }) => (
        <BaseAlert
          type={type}
          title={title}
          content={content}
          onClose={onClose}
          colorMode={colorMode}
        />
      ),
    });
  }, [colorMode, content, title, toast, type]);

  const showToast = (props) => {
    if (unique && toast.isActive(toastID.current)) return;

    const { type: newType, title: newTitle, content: newContent } = props;
    toastRef.current = toast({
      id: unique ? toastID.current : undefined,
      position,
      // eslint-disable-next-line react/display-name
      render: ({ onClose }) => (
        <BaseAlert
          type={newType ?? type}
          title={newTitle ?? title}
          content={newContent ?? content}
          onClose={onClose}
          colorMode={colorMode}
        />
      ),
    });
  };

  return showToast;
};
