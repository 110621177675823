import { Box, Grid } from "@chakra-ui/react";
import Head from "next/head";
import { useEffect } from "react";
import { uniqueNamesGenerator } from "unique-names-generator";
import { Body } from "../components/layout/Body";
import { Footer } from "../components/layout/Footer";
import { Header } from "../components/layout/Header";
import { nameConfig, seedConfig } from "../config/uniqueNamesGenerator";
import { initFirestoreSelector } from "../state/firestore";
import { setMeSelector } from "../state/peer";
import { useStore } from "../state/store";

export default function Home({ myName, myID }) {
  const setMe = useStore(setMeSelector);
  const initFirestore = useStore(initFirestoreSelector);

  useEffect(() => {
    setMe({ id: myID, name: myName });
    initFirestore();
  }, [initFirestore, myID, myName, setMe]);

  return (
    <Box>
      <Head>
        <title>WebPipe | Smart Transfer</title>
        <meta
          name="description"
          content="Easy, secure, lossless and fast way to transfer files between devices"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Grid height="100vh" templateRows="auto 1fr auto">
        <Header />
        <Body />
        <Footer />
      </Grid>
    </Box>
  );
}

Home.getInitialProps = async (ctx) => {
  return {
    myName: uniqueNamesGenerator(nameConfig),
    myID: uniqueNamesGenerator(seedConfig),
  };
};
