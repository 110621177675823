import {
  DrawerFooter,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  useColorModeValue,
} from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useRef, useState } from "react";
import { FiSend } from "@react-icons/all-files/fi/FiSend";
import { addMessageSelector } from "../../state/chat";
import { meSelector, peerInstanceSelector } from "../../state/peer";
import { useStore } from "../../state/store";

export const ChatFooter = ({ isConnected }) => {
  const [inputValue, setInputValue] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const { name: userName } = useStore(meSelector);
  const peer = useStore(peerInstanceSelector);
  const addMessage = useStore(addMessageSelector);

  const handleClick = () => {
    if (!inputValue) {
      inputRef.current.focus();
      return;
    }

    if (peer) {
      const data = {
        id: nanoid(),
        name: userName,
        value: inputValue,
        createdAt: new Date().getTime(),
        type: "MESSAGE",
      };
      peer.send(JSON.stringify(data));

      addMessage(data);
      setInputValue("");
      inputRef.current.focus();
    }
  };

  return (
    <DrawerFooter borderTopWidth="1px">
      <InputGroup size="md">
        <Input
          ref={inputRef}
          type={"text"}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          placeholder="Say something..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleClick();
            }
          }}
          isDisabled={!isConnected}
        />
        <InputRightAddon padding={0}>
          <IconButton
            color={useColorModeValue("gray.600", "gray.300")}
            variant="ghost"
            aria-label="Send message"
            onClick={handleClick}
            icon={<FiSend fontSize="22px" />}
            isDisabled={!isConnected}
            _focus={{ outline: "none" }}
            _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
          />
        </InputRightAddon>
      </InputGroup>
    </DrawerFooter>
  );
};
