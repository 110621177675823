import {
  Box,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import fileSize from "filesize";
import { FiEdit3 } from "@react-icons/all-files/fi/FiEdit3";
import { FiHelpCircle } from "@react-icons/all-files/fi/FiHelpCircle";
import { FiRepeat } from "@react-icons/all-files/fi/FiRepeat";

export const FilePopover = ({ children, file }) => {
  const { size, name, percent, lastModified, type } = file;
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent boxShadow="2xl" _focus={{ outline: "none" }}>
          <PopoverArrow />
          <PopoverHeader fontSize="sm" fontWeight="semibold">
            <Text maxWidth="90%">{name}</Text>
          </PopoverHeader>
          <PopoverCloseButton
            marginTop={1}
            _focus={{ outline: "none" }}
            _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
          />
          <PopoverBody fontSize="sm">
            <Stack>
              <HStack>
                <Box>
                  <FiHelpCircle />
                </Box>
                <Text>Type:</Text>
                <Text flex={1} textAlign="end" isTruncated>
                  <Tooltip label={type}>{type}</Tooltip>
                </Text>
              </HStack>
              <HStack>
                <Box>
                  <FiRepeat />
                </Box>
                <Text>Transfered:</Text>
                <Text flex={1} textAlign="end">
                  {`${fileSize((size * percent) / 100)} of
                    ${fileSize(size)}`}
                </Text>
              </HStack>
              <HStack>
                <Box>
                  <FiEdit3 />
                </Box>
                <Text>Modified:</Text>
                <Text flex={1} textAlign="end">
                  <Tooltip label={new Date(lastModified).toUTCString()}>
                    {new Date(lastModified).toDateString()}
                  </Tooltip>
                </Text>
              </HStack>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
