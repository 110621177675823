import { useEffect } from "react";
import { peerInstanceSelector } from "../state/peer";
import { useStore } from "../state/store";

export const usePeerDebug = () => {
  const peer = useStore(peerInstanceSelector);

  useEffect(() => {
    if (peer && process.env.NEXT_PUBLIC_ENV !== "PRODUCTION") {
      peer._debug = console.log;
    }
  }, [peer]);

  return;
};
