import { ToastPosition, useColorMode, useToast } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useEffect, useRef } from "react";
import {
  ActionAlert,
  ActionAlertProps,
} from "../components/alerts/ActionAlert";

export interface useActionToastProps extends ActionAlertProps {
  id?: string;
  unique: boolean;
  position: ToastPosition;
}

export const useActionToast = ({
  id,
  fileID,
  name,
  message,
  action,
  position,
  unique,
}: useActionToastProps) => {
  const toast = useToast();

  const toastRef = useRef(null);
  const { colorMode } = useColorMode();
  const toastID = useRef(id ?? nanoid());

  useEffect(() => {
    toast.update(toastRef.current, {
      // eslint-disable-next-line react/display-name
      render: ({ onClose }) => (
        <ActionAlert
          fileID={fileID}
          name={name}
          message={message}
          action={action}
          onClose={onClose}
          colorMode={colorMode}
        />
      ),
    });
  }, [colorMode, fileID, message, name, action, toast]);

  const showToast = ({ fileID, name, message, action }) => {
    if (unique && toast.isActive(toastID.current)) return;

    toastRef.current = toast({
      id: unique ? toastID.current : undefined,
      position,
      // eslint-disable-next-line react/display-name
      render: ({ onClose }) => (
        <ActionAlert
          fileID={fileID}
          name={name}
          message={message}
          action={action}
          onClose={onClose}
          colorMode={colorMode}
        />
      ),
    });
  };

  return showToast;
};
