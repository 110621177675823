import { Stack, Text } from "@chakra-ui/react";

export function Message({ userName, isMe, children, ...props }) {
  return (
    <Stack
      textAlign={isMe ? "right" : "left"}
      {...props}
      paddingY={2}
      paddingX={{ base: 3, md: 4 }}
      spacing={1}
      shadow="md"
      rounded="md"
      border="1px solid"
      width="auto"
      alignSelf={isMe ? "flex-end" : "flex-start"}
      maxWidth="90%"
      borderColor="blackAlpha.50"
    >
      <Text
        fontSize="xs"
        fontWeight="semibold"
        color={isMe ? "gray.500" : "blue.400"}
        whiteSpace="nowrap"
      >
        {isMe ? "You" : userName}:
      </Text>
      <Text>{children}</Text>
    </Stack>
  );
}
