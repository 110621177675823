import {
  Box,
  HStack,
  IconButton,
  Link,
  Progress,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { CgClose } from "@react-icons/all-files/cg/CgClose";
import { FiDownload } from "@react-icons/all-files/fi/FiDownload";
import { HiOutlineRefresh } from "@react-icons/all-files/hi/HiOutlineRefresh";
import { IoPauseOutline } from "@react-icons/all-files/io5/IoPauseOutline";
import { getColorFromStatus, getFileTypeIcon } from "../../common/utils";
import { downloadFileSelector, removeFileSelector } from "../../state/files";
import {
  connectionStatusSelector,
  peerInstanceSelector,
} from "../../state/peer";
import { konamiSelector, StoreType, useStore } from "../../state/store";
import { FilePopover } from "./FilePopover";

export const File = ({ id, itsMe }) => {
  const file = useStore(
    useCallback((state: StoreType) => state.files.fileList[id], [id])
  );

  const {
    state,
    name,
    type,
    percent,
    sendedAt,
    savedAt,
    senderName,
    transfer,
  } = file;

  const [status, setStatus] = useState(itsMe ? "Uploading" : "Downloading");
  const [isResumeDisabled, setIsResumeDisabled] = useState(false);

  const peer = useStore(peerInstanceSelector);
  const downloadFile = useStore(downloadFileSelector);
  const removeFile = useStore(removeFileSelector);
  const connectionStatus = useStore(connectionStatusSelector);

  const konami = useStore(konamiSelector);

  useEffect(() => {
    if (percent === 100) setStatus(itsMe ? "Sent" : "Received");
  }, [itsMe, percent]);

  useEffect(() => {
    if (savedAt > 0) setStatus(itsMe ? "Downloaded" : "Saved");
  }, [itsMe, savedAt]);

  useEffect(() => {
    if (state === "TRANSFERING") {
      if (isResumeDisabled) setIsResumeDisabled(false);
      setStatus(itsMe ? "Uploading" : "Downloading");
    } else if (state === "PAUSED" && status !== "Paused") {
      setIsResumeDisabled(true);
      setStatus("Paused");
    }
  }, [state, itsMe]);

  const handlePause = () => {
    transfer?.pause?.();
    setStatus("Paused");
    peer.send(JSON.stringify({ type: "FILE-PAUSED", id: id }));
  };

  const handleResume = () => {
    transfer?.resume?.();
    setStatus(itsMe ? "Uploading" : "Downloading");
    peer.send(JSON.stringify({ type: "FILE-RESUMED", id: id }));
  };

  const handleSave = () => {
    downloadFile(id);
    if (!itsMe) {
      if (connectionStatus === "CONNECTED")
        peer.send(JSON.stringify({ type: "FILE-SAVED", id: id }));
      setStatus("Saved");
    }
  };

  const handleCancel = () => {
    transfer?.cancel?.();
    removeFile(id);
    peer.send(JSON.stringify({ type: "FILE-CANCELED", id: id }));
  };

  return (
    <Stack id={id} py={2} px={1} position="relative">
      <HStack spacing={2}>
        <Stack spacing={1} flex={1}>
          <FilePopover file={file}>
            <Link>
              <HStack
                fontSize={konami ? "10px" : "sm"}
                paddingTop={1}
                color={useColorModeValue("gray.700", "gray.300")}
              >
                <Box>{getFileTypeIcon({ type })}</Box>
                <Text noOfLines={2} wordBreak="break-all">
                  {name}
                </Text>
              </HStack>
            </Link>
          </FilePopover>
          <Text
            color={useColorModeValue("gray.500", "gray.400")}
            fontSize={konami ? "9px" : "xs"}
          >
            {itsMe ? `You:` : `${senderName}:`}
          </Text>
          <Text
            fontSize={konami ? "10px" : "sm"}
            color={useColorModeValue("gray.500", "gray.400")}
          >
            {percent >> 0}% · {status}
          </Text>
        </Stack>
        <HStack spacing={3}>
          {(status === "Downloading" ||
            status === "Paused" ||
            status === "Uploading") && (
            <>
              {status === "Downloading" || status === "Uploading" ? (
                <Tooltip label="Pause" fontSize="xs" closeOnClick={false}>
                  <IconButton
                    rounded={"full"}
                    aria-label={`Pause ${name}`}
                    size="sm"
                    onClick={handlePause}
                    icon={<IoPauseOutline fontSize="18px" />}
                    _focus={{ outline: "none" }}
                    _focusVisible={{
                      boxShadow: "var(--chakra-shadows-outline)",
                    }}
                  ></IconButton>
                </Tooltip>
              ) : (
                <Tooltip label="Resume" fontSize="xs" closeOnClick={false}>
                  <IconButton
                    rounded={"full"}
                    aria-label={`Resume ${name}`}
                    size="sm"
                    onClick={handleResume}
                    icon={<HiOutlineRefresh fontSize="18px" />}
                    isDisabled={isResumeDisabled}
                    _focus={{ outline: "none" }}
                    _focusVisible={{
                      boxShadow: "var(--chakra-shadows-outline)",
                    }}
                  ></IconButton>
                </Tooltip>
              )}
              <Tooltip label="Remove" fontSize="xs" closeOnClick={true}>
                <IconButton
                  rounded={"full"}
                  aria-label={`Cancel ${name}`}
                  size="sm"
                  onClick={handleCancel}
                  icon={<CgClose fontSize="18px" />}
                  _focus={{ outline: "none" }}
                  _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
                ></IconButton>
              </Tooltip>
            </>
          )}

          {(status === "Received" ||
            status === "Saved" ||
            status === "Sent" ||
            status === "Downloaded") && (
            <Tooltip label="Save" fontSize="xs" closeOnClick={true}>
              <IconButton
                rounded={"full"}
                aria-label={`Save ${name}`}
                size="sm"
                onClick={handleSave}
                icon={<FiDownload fontSize="18px" />}
                _focus={{ outline: "none" }}
                _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
              ></IconButton>
            </Tooltip>
          )}
        </HStack>
      </HStack>
      <Progress
        value={Number(percent)}
        size="xs"
        height="2px"
        colorScheme={getColorFromStatus(status)}
      />
    </Stack>
  );
};
