import { Center } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useActionToast } from "../../hooks/useActionToast";
import { filesSelector } from "../../state/files";
import { meSelector, otherSelector } from "../../state/peer";
import { useStore } from "../../state/store";
import { Card } from "../common/Card";
import { HandwritedFilesMessage } from "../handwrited/HandwritedFilesMessage";
import { File } from "./File";

export const FileList = () => {
  const files = useStore(filesSelector);
  const { name: myName } = useStore(meSelector);
  const { name: otherName } = useStore(otherSelector);

  const filesLength = Object.values(files).length;

  const prevFilesLength = useRef(0);

  const showToast = useActionToast({
    fileID: "",
    message: "",
    name: "",
    action: "added",
    position: "top-right",
    unique: false,
  });

  useEffect(() => {
    if (files && prevFilesLength.current < filesLength) {
      const file = Object.values(files)[filesLength - 1];
      if (file && file.senderName !== myName) {
        showToast({
          fileID: file.id,
          message: file.name,
          name: otherName,
          action: "added",
        });
      }
    }
    prevFilesLength.current = filesLength;
  }, [files, filesLength, myName, otherName, prevFilesLength, showToast]);

  return (
    <Center position="relative" width="100%" maxWidth="360px" paddingBottom={4}>
      <HandwritedFilesMessage />
      <Card padding={6}>
        {Object.values(files)
          .sort(function (x, y) {
            return y.sendedAt - x.sendedAt;
          })
          .map((file) => (
            <File
              key={file.id}
              id={file.id}
              itsMe={file.senderName === myName}
            />
          ))}
      </Card>
    </Center>
  );
};
