import { Box, chakra, Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";
import { BsLightningFill } from "@react-icons/all-files/bs/BsLightningFill";
import { IoMdAlert } from "@react-icons/all-files/io/IoMdAlert";
import { IoMdCheckmarkCircle } from "@react-icons/all-files/io/IoMdCheckmarkCircle";

export type BaseAlertProps = {
  type: "SUCCESS" | "INFO" | "WARNING" | "ERROR";
  title: string;
  content: ReactNode;
  colorMode?: string;
  onClose?: () => void;
};

export function BaseAlert({
  type,
  title,
  content,
  colorMode,
  onClose,
}: BaseAlertProps) {
  const handleOnClick = () => {
    onClose?.();
  };

  const colors = {
    ERROR: useColorModeValue("red.400", "red.600"),
    WARNING: useColorModeValue("yellow.400", "yellow.600"),
    INFO: useColorModeValue("blue.400", "blue.600"),
    SUCCESS: useColorModeValue("green.400", "green.600"),
  };

  const icon = useMemo(() => {
    switch (type) {
      case "ERROR":
        return BsLightningFill;

      case "WARNING":
        return IoMdAlert;

      case "INFO":
        return IoMdAlert;

      case "SUCCESS":
        return IoMdCheckmarkCircle;

      default:
        return IoMdCheckmarkCircle;
    }
  }, [type]);

  return (
    <Flex
      width="full"
      padding={1}
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={handleOnClick}
    >
      <Flex
        maxW="sm"
        w="full"
        mx="auto"
        bg={useColorModeValue("white", "gray.800")}
        shadow="md"
        rounded="lg"
        overflow="hidden"
        border="1px solid"
        color={useColorModeValue("gray.100", "gray.700")}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          w={12}
          bg={colors[type]}
        >
          <Icon as={icon} color="gray.100" boxSize={6} />
        </Flex>

        <Box mx={-3} py={2} px={4}>
          <Box mx={3}>
            <chakra.span color={colors[type]} fontWeight="bold">
              {title}
            </chakra.span>
            <Box
              color={useColorModeValue("gray.600", "gray.300")}
              fontSize="sm"
            >
              {content}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}
