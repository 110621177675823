import { DrawerHeader, HStack, Text } from "@chakra-ui/react";
import { FiInfo } from "@react-icons/all-files/fi/FiInfo";

export const InformationHeader = () => {
  return (
    <DrawerHeader borderBottomWidth="1px">
      <HStack alignItems="center" spacing={3}>
        <FiInfo fontSize="26px" />
        <Text>Information</Text>
      </HStack>
    </DrawerHeader>
  );
};
