import { useEffect } from "react";
import { roomSelector } from "../state/firestore";
import { connectionStatusSelector, peerInstanceSelector } from "../state/peer";
import { useStore } from "../state/store";

export const useHostController = () => {
  const peer = useStore(peerInstanceSelector);
  const room = useStore(roomSelector);
  const connectionStatus = useStore(connectionStatusSelector);

  useEffect(() => {
    if (peer && room && connectionStatus !== "CONNECTED") {
      console.log("-- host --");

      const { offerCandidates, answerCandidates } = room;

      const unsubscribe = answerCandidates.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const signal = change.doc.data();
          if (peer.readable) {
            peer.signal(signal);
          }
        });
      });

      peer.removeAllListeners("signal");

      peer.on("signal", (data) => {
        data.timestamp = new Date().getTime();
        if (data.renegotiate || data.transceiverRequest) return;
        offerCandidates.add(data);
      });

      return () => {
        unsubscribe?.();
        peer.removeAllListeners("signal");
      };
    }
  }, [peer, room, connectionStatus]);

  return;
};
