import { LinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Card } from "../../common/Card";

export const ExampleConnect = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");
  const count = useRef(0);

  useEffect(() => {
    const phrase = "other-peerid-321";

    const interval = setInterval(() => {
      if (value === phrase || count.current > phrase.length) {
        count.current++;
        if (count.current === phrase.length + 1) {
          setIsDisabled(false);
        }
        if (count.current === phrase.length + 2) {
          setIsActive(true);
        }
        if (count.current === phrase.length + 6) {
          setIsActive(false);
          setIsLoading(true);
          setIsDisabled(true);
        }
        if (count.current === phrase.length + 10) {
          setValue("");
          setIsLoading(false);
        }
        if (count.current === phrase.length + 14) {
          count.current = 0;
        }
      } else if (count.current < phrase.length) {
        setValue((value) => value + phrase[count.current]);
        count.current++;
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [value]);

  return (
    <>
      <Card position="absolute" top="0px" />
      <Box
        position="absolute"
        top="50%"
        maxWidth="360px"
        width="100%"
        alignSelf="center"
        paddingBottom={4}
        paddingX={1}
        userSelect="none"
        pointerEvents="none"
      >
        <Text
          fontSize="sm"
          color={useColorModeValue("gray.600", "gray.400")}
          marginLeft={1.5}
          marginBottom={1}
          userSelect="none"
        >
          Here goes the id of your peer
        </Text>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <LinkIcon color={useColorModeValue("gray.300", "gray.600")} />
          </InputLeftElement>
          <Input
            placeholder="ID or link to connect"
            backgroundColor={useColorModeValue("white", "gray.700")}
            width="100%"
            value={value}
            readOnly
          />
          <InputRightAddon padding="0">
            <Center>
              <Button
                isLoading={isLoading}
                isActive={isActive}
                borderLeftRadius="0"
                colorScheme="blue"
                isDisabled={isDisabled}
                _focus={{ outline: "none" }}
                _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
              >
                Connect
              </Button>
            </Center>
          </InputRightAddon>
        </InputGroup>
      </Box>
    </>
  );
};
