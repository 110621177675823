import { Link, LinkProps } from "@chakra-ui/react";

export const BlankLink = ({ children, ...props }: LinkProps) => {
  return (
    <Link
      borderRadius="sm"
      target="_blank"
      {...props}
      _focus={{ outline: "none" }}
      _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
    >
      {children}
    </Link>
  );
};
