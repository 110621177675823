import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { FiMessageSquare } from "@react-icons/all-files/fi/FiMessageSquare";

export type MessageAlertProps = {
  name: string;
  message: string;
  colorMode?: string;
  onClose?: () => void;
  onOpen?: () => void;
};

export const MessageAlert = ({
  name,
  message,
  onClose,
  onOpen,
  colorMode,
}: MessageAlertProps) => {
  const handleOnClick = () => {
    onOpen?.();
    onClose?.();
  };

  return (
    <Flex
      width="full"
      padding={1}
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={handleOnClick}
    >
      <Flex
        maxWidth="sm"
        width="full"
        marginX="auto"
        shadow="md"
        rounded="lg"
        overflow="hidden"
        background={useColorModeValue("white", "gray.700")}
        color={useColorModeValue("gray.600", "gray.200")}
      >
        <Flex width={2} bg={useColorModeValue("blue.400", "blue.600")}></Flex>
        <Flex alignItems="center" paddingX={2} paddingY={3}>
          <Avatar
            bg={useColorModeValue("blue.400", "blue.600")}
            color="white"
            boxSize={8}
            icon={<FiMessageSquare fontSize="1rem" />}
          />
          <Box mx={3}>
            <Text>
              <b>{name}</b> say:
            </Text>
            <Text>{message}</Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
