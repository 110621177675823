import {
  Flex,
  HStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { RiMoonLine } from "@react-icons/all-files/ri/RiMoonLine";
import { RiSunLine } from "@react-icons/all-files/ri/RiSunLine";
import { Chat } from "../chat/Chat";
import { RoundedIconButton } from "../common/RoundedIconButton";
import { Information } from "../information/Information";

export const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const headeRef = useRef();

  const backgroundColor = useColorModeValue("gray.50", "gray.800");

  useEffect(() => {
    const listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      const scrolled =
        winScroll /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight);

      setIsSticky(scrolled > 0);
    };

    window.addEventListener("scroll", listenToScroll);

    return () => {
      window.removeEventListener("scroll", listenToScroll);
    };
  }, []);

  return (
    <Flex
      ref={headeRef}
      paddingX={4}
      paddingY={2}
      justifyContent="space-between"
      top="0px"
      width="100%"
      position="sticky"
      background={isSticky ? backgroundColor : "transparent"}
      boxShadow={isSticky ? "md" : undefined}
      transition="box-shadow 0.3s ease, background 0.3s ease"
      zIndex={100}
    >
      <HStack>
        <Chat />
      </HStack>
      <HStack spacing={3}>
        <RoundedIconButton
          size="sm"
          aria-label="color mode"
          icon={
            colorMode === "light" ? (
              <RiMoonLine fontSize="18px" />
            ) : (
              <RiSunLine fontSize="18px" />
            )
          }
          onClick={toggleColorMode}
        />
        <Information />
      </HStack>
    </Flex>
  );
};
