import { CopyIcon } from "@chakra-ui/icons";
import {
  Center,
  Grid,
  Heading,
  HStack,
  Text,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
import { useBaseToast } from "../hooks/useBaseToast";
import { roomSelector } from "../state/firestore";
import { connectionStatusSelector, otherSelector } from "../state/peer";
import { konamiSelector, useStore } from "../state/store";

export const RoomID = ({ roomID, hasFiles }) => {
  const showToast = useBaseToast({
    id: "link-toast",
    type: "INFO",
    title: "Link copied!",
    content: "Share it to connect.",
    position: "bottom",
    unique: true,
  });
  const konami = useStore(konamiSelector);
  const { name: userName } = useStore(otherSelector);
  const room = useStore(roomSelector);

  const url = `${process.env.NEXT_PUBLIC_URL}${roomID}`;
  const { onCopy } = useClipboard(url);

  const handleOnClick = () => {
    if (!room) return;
    onCopy();
    showToast({});
  };

  const connectionStatus = useStore(connectionStatusSelector);

  const isConnected = connectionStatus === "CONNECTED";

  const colorDisabled = useColorModeValue("gray.300", "gray.600");
  const colorEnabled = useColorModeValue("gray.700", "gray.200");

  return (
    <Grid
      paddingTop={hasFiles && isConnected ? 4 : 8}
      gridTemplateColumns={hasFiles && isConnected ? "auto auto" : undefined}
      color={useColorModeValue("gray.700", "gray.100")}
    >
      <Center>
        <Text
          userSelect="none"
          fontSize={konami ? "10px" : "xs"}
          textTransform="uppercase"
          color={useColorModeValue("gray.600", "gray.300")}
        >
          {isConnected ? "Piped with" : "Your ID"}
        </Text>
      </Center>
      {isConnected ? (
        <Center
          minHeight="54px"
          maxWidth="320px"
          width="100%"
          marginLeft={1}
          alignSelf="center"
        >
          <Text as="b" fontFamily={"Caveat"} fontSize="3xl">
            {userName}
          </Text>
        </Center>
      ) : (
        <Center minHeight="54px" minWidth="100%">
          <HStack
            spacing={3}
            cursor={room ? "pointer" : undefined}
            color={room ? colorEnabled : colorDisabled}
            userSelect={room ? undefined : "none"}
            onClick={handleOnClick}
          >
            <Heading
              as={room ? undefined : "s"}
              fontSize={konami ? "md" : "2xl"}
              fontFamily={konami ? "custom" : "body"}
              fontWeight={600}
            >
              {roomID}
            </Heading>
            <CopyIcon fontSize={"xl"} />
          </HStack>
        </Center>
      )}
    </Grid>
  );
};
