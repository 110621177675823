import { useEffect } from "react";
import { destinationRoomSelector } from "../state/firestore";
import { connectionStatusSelector, peerInstanceSelector } from "../state/peer";
import { useStore } from "../state/store";

export const useGuestController = () => {
  const peer = useStore(peerInstanceSelector);
  const destinationRoom = useStore(destinationRoomSelector);
  const connectionStatus = useStore(connectionStatusSelector);

  useEffect(() => {
    if (destinationRoom && peer && connectionStatus !== "CONNECTED") {
      const get = async () => {
        console.log("-- guest --");
        const { offerCandidates, answerCandidates } = destinationRoom;

        const callData = await offerCandidates.get();

        const data = callData.docs.map((doc) => doc.data());

        if (data?.length) {
          const sorted = data.sort((a, b) =>
            a.timestamp > b.timestamp ? 1 : -1
          );

          sorted.forEach((signal) => {
            if (peer.readable) {
              console.log("GUEST SIGNAL: ", signal);
              peer.signal(signal);
            }
          });

          peer.removeAllListeners("signal");

          peer.on("signal", (data) => {
            data.timestamp = new Date().getTime();
            if (data.renegotiate || data.transceiverRequest) return;
            answerCandidates.add(data);
          });
        }
      };
      get();

      return () => {
        peer.removeAllListeners("signal");
      };
    }
  }, [connectionStatus, destinationRoom, peer]);

  return;
};
