import {
  Box,
  Center,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { FiMessageSquare } from "@react-icons/all-files/fi/FiMessageSquare";
import { useMessageToast } from "../../hooks/useMessageToast";
import {
  increaseUnreadedMessagesSelector,
  messagesSelector,
  resetUnreadedMessagesSelector,
  unreadedMessagesSelector,
} from "../../state/chat";
import { connectionStatusSelector, meSelector } from "../../state/peer";
import { useStore } from "../../state/store";
import { RoundedIconButton } from "../common/RoundedIconButton";
import { ChatBody } from "./ChatBody";
import { ChatFooter } from "./ChatFooter";
import { ChatHeader } from "./ChatHeader";

export const Chat = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef();
  const resetUnreadedMessages = useStore(resetUnreadedMessagesSelector);
  const unreadedMessages = useStore(unreadedMessagesSelector);
  const increaseUnreadedMessages = useStore(increaseUnreadedMessagesSelector);

  const connectionStatus = useStore(connectionStatusSelector);
  const { name: myName } = useStore(meSelector);
  const messages = useStore(messagesSelector);

  const prevMessageCount = useRef(messages.length);

  const showToast = useMessageToast({
    name: "",
    message: "",
    position: "top-right",
    onOpen: onOpen,
    unique: false,
  });

  useEffect(() => {
    const message = messages[messages.length - 1];
    if (
      !isOpen &&
      message?.name !== myName &&
      prevMessageCount.current != messages.length
    ) {
      showToast({
        name: message.name,
        message: message.value,
      });
      increaseUnreadedMessages();
    }
    prevMessageCount.current = messages.length;
  }, [increaseUnreadedMessages, isOpen, messages, myName, showToast]);

  useEffect(() => {
    if (isOpen) {
      resetUnreadedMessages();
    }
  }, [isOpen, resetUnreadedMessages]);

  return (
    <>
      <Box position="relative">
        <RoundedIconButton
          ref={buttonRef}
          size="sm"
          aria-label="chat"
          icon={<FiMessageSquare fontSize="18px" />}
          onClick={onOpen}
        />
        {unreadedMessages ? (
          <Center
            pointerEvents="none"
            boxSize="1.6em"
            color="white"
            bg="blue.400"
            zIndex="100"
            position="absolute"
            rounded="full"
            textAlign="center"
            fontSize="xs"
            fontWeight="semibold"
            top="0"
            right="-4px"
          >
            {unreadedMessages}
          </Center>
        ) : null}
      </Box>
      <Drawer
        size="md"
        placement="left"
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={buttonRef}
        preserveScrollBarGap={true}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            marginTop={2}
            _focus={{ outline: "none" }}
            _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
          />
          <ChatHeader isConnected={connectionStatus === "CONNECTED"} />
          <ChatBody
            isOpen={isOpen}
            isConnected={connectionStatus === "CONNECTED"}
          />
          <ChatFooter isConnected={connectionStatus === "CONNECTED"} />
        </DrawerContent>
      </Drawer>
    </>
  );
};
