import { LinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useBaseToast } from "../hooks/useBaseToast";
import { initSimplePeerFileSelector } from "../state/files";
import {
  firestoreSelector,
  initDestinationRoomSelector,
} from "../state/firestore";
import {
  initPeerSelector,
  otherSelector,
  setConnectionStatusSelector,
} from "../state/peer";
import { konamiSelector, useStore } from "../state/store";

export const ConnectInput = ({ myID, connectionStatus, ...props }) => {
  const other = useStore(otherSelector);

  const ID = other.id && !other.name ? other.id : null;

  const [value, setValue] = useState(ID ?? "");
  const [isDisabled, setIsDisabled] = useState(ID ? false : true);

  const initDestinationRoom = useStore(initDestinationRoomSelector);
  const initPeer = useStore(initPeerSelector);
  const initSimplePeerFile = useStore(initSimplePeerFileSelector);

  const setConnectionStatus = useStore(setConnectionStatusSelector);

  const firestore = useStore(firestoreSelector);

  const konami = useStore(konamiSelector);

  const showToast = useBaseToast({
    id: "error-connect",
    type: "ERROR",
    title: "Oops, something's happened!",
    content: "It seems that the identifier does not exist",
    position: "bottom",
    unique: true,
  });

  const handleConnect = async (value) => {
    setConnectionStatus("CONNECTING");
    const exists = await initDestinationRoom(value);
    if (exists) {
      initPeer(false);
      initSimplePeerFile();
    } else {
      showToast({});
      setConnectionStatus("DISCONNECTED");
    }
  };

  useEffect(() => {
    if (ID && connectionStatus === "DISCONNECTED" && firestore) {
      console.log("--- auto connect ---");
      handleConnect(ID);
      setConnectionStatus("CONNECTING");
    }
  }, [ID, connectionStatus, firestore, setConnectionStatus]);

  useEffect(() => {
    if (connectionStatus === "CONNECTED") {
      setValue("");
    }
  }, [connectionStatus]);

  const handleOnClick = () => {
    if (!value || isDisabled) return;
    if (typeof value === "string") {
      handleConnect(value.toLowerCase());
    }
  };

  const handleOnChange = (event) => {
    const value = event.target.value;
    const url = `${process.env.NEXT_PUBLIC_URL}`;

    const splited = value.split("-");
    if (
      splited.length === 3 &&
      splited[2].length === 3 &&
      value !== myID &&
      !value.includes(url)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    setValue(value);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOnClick();
    }
  };

  const handlePasteCapture = (event: any) => {
    const paste = event.clipboardData.getData("text");
    const url = `${process.env.NEXT_PUBLIC_URL}`;

    const ID = paste.split(url)[1] ?? paste;
    const splited = ID.split("-");

    console.log(ID);
    console.log(url);
    if (
      splited.length === 3 &&
      splited[2].length === 3 &&
      ID !== myID &&
      !ID.includes(url)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setValue(ID);
  };

  const handleOnPaste = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <Box
      maxWidth="360px"
      width="100%"
      alignSelf="center"
      paddingBottom={4}
      paddingX={1}
      {...props}
    >
      <Text
        fontSize={konami ? "10px" : "sm"}
        color={useColorModeValue("gray.600", "gray.400")}
        marginLeft={1.5}
        marginBottom={1}
      >
        Here goes the id of your peer
      </Text>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <LinkIcon color={useColorModeValue("gray.300", "gray.600")} />
        </InputLeftElement>
        <Input
          fontSize={konami ? "12px" : undefined}
          placeholder="ID or link to connect"
          backgroundColor={useColorModeValue("white", "gray.700")}
          width="100%"
          value={value}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onPasteCapture={handlePasteCapture}
          onPaste={handleOnPaste}
        />
        <InputRightAddon padding="0">
          <Center>
            <Button
              isLoading={connectionStatus === "CONNECTING"}
              borderLeftRadius="0"
              colorScheme="blue"
              onClick={handleOnClick}
              isDisabled={isDisabled}
              fontSize={konami ? "xs" : undefined}
              _focus={{ outline: "none" }}
              _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
            >
              Connect
            </Button>
          </Center>
        </InputRightAddon>
      </InputGroup>
    </Box>
  );
};
