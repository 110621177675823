export const EmptyStreet = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="be3e1a43-0c7b-454d-900a-5620992e061d"
      data-name="Layer 1"
      width="997.86122"
      height="450.8081"
      viewBox="0 0 997.86122 450.8081"
      {...props}
    >
      <rect
        x="871.99152"
        y="181.55804"
        width="30.15944"
        height="104.39806"
        fill="#f2f2f2"
      />
      <polygon
        points="922.068 266.317 848.715 179.052 701.475 180.398 612.156 267.396 613.961 268.556 613.316 268.556 613.316 449.513 921.871 449.513 921.871 268.556 922.068 266.317"
        fill="#f2f2f2"
      />
      <polygon
        points="848.792 179.238 757.154 286.674 757.154 449.513 921.871 449.513 921.871 266.236 848.792 179.238"
        fill="#e6e6e6"
      />
      <rect
        x="823.27242"
        y="359.46083"
        width="33.6394"
        height="29.73333"
        fill="#3f3d56"
      />
      <rect
        x="823.27242"
        y="307.99568"
        width="33.6394"
        height="29.26181"
        fill="#3f3d56"
      />
      <rect
        x="823.27242"
        y="359.46083"
        width="33.6394"
        height="29.73333"
        fill="#fff"
      />
      <rect
        x="823.27242"
        y="307.99568"
        width="33.6394"
        height="29.26181"
        fill="#fff"
      />
      <rect
        x="673.77661"
        y="351.57128"
        width="33.6394"
        height="29.73333"
        fill="#fff"
      />
      <rect
        x="673.77661"
        y="300.10613"
        width="33.6394"
        height="29.26181"
        fill="#fff"
      />
      <rect
        x="633.99152"
        y="181.55804"
        width="30.15944"
        height="104.39806"
        fill="#f2f2f2"
      />
      <polygon
        points="684.068 266.317 610.715 179.052 463.475 180.398 374.156 267.396 375.961 268.556 375.316 268.556 375.316 449.513 683.871 449.513 683.871 268.556 684.068 266.317"
        fill="#f2f2f2"
      />
      <polygon
        points="610.792 179.238 519.154 286.674 519.154 449.513 683.871 449.513 683.871 266.236 610.792 179.238"
        fill="#e6e6e6"
      />
      <rect
        x="585.27242"
        y="359.46083"
        width="33.6394"
        height="29.73333"
        fill="#3f3d56"
      />
      <rect
        x="585.27242"
        y="307.99568"
        width="33.6394"
        height="29.26181"
        fill="#3f3d56"
      />
      <rect
        x="585.27242"
        y="359.46083"
        width="33.6394"
        height="29.73333"
        fill="#fff"
      />
      <rect
        x="585.27242"
        y="307.99568"
        width="33.6394"
        height="29.26181"
        fill="#fff"
      />
      <rect
        x="435.77661"
        y="351.57128"
        width="33.6394"
        height="29.73333"
        fill="#fff"
      />
      <rect
        x="435.77661"
        y="300.10613"
        width="33.6394"
        height="29.26181"
        fill="#fff"
      />
      <rect
        x="380.1536"
        y="91.46021"
        width="40.30032"
        height="139.50112"
        fill="#f2f2f2"
      />
      <polygon
        points="447.068 204.718 349.051 88.112 152.302 89.91 32.951 206.161 35.362 207.711 34.501 207.711 34.501 449.513 446.804 449.513 446.804 207.711 447.068 204.718"
        fill="#f2f2f2"
      />
      <polygon
        points="349.153 88.36 226.702 231.921 226.702 449.513 446.804 449.513 446.804 204.611 349.153 88.36"
        fill="#e6e6e6"
      />
      <rect
        x="315.05306"
        y="329.18147"
        width="44.95039"
        height="39.73094"
        fill="#3f3d56"
      />
      <rect
        x="315.05306"
        y="260.41156"
        width="44.95039"
        height="39.10088"
        fill="#3f3d56"
      />
      <rect
        x="315.05306"
        y="329.18147"
        width="44.95039"
        height="39.73094"
        fill="#fff"
      />
      <rect
        x="315.05306"
        y="260.41156"
        width="44.95039"
        height="39.10088"
        fill="#fff"
      />
      <rect
        x="115.29041"
        y="318.63912"
        width="44.95039"
        height="39.73094"
        fill="#fff"
      />
      <rect
        x="115.29041"
        y="249.8692"
        width="44.95039"
        height="39.10088"
        fill="#fff"
      />
      <rect y="448.61997" width="963.95079" height="2" fill="#3f3d56" />
      <ellipse
        cx="151.87223"
        cy="352.47204"
        rx="29.09932"
        ry="59.37437"
        fill="#3f3d56"
      />
      <path
        d="M255.62882,674.25425c-11.65458-69.92526-.11734-139.59789.00056-140.29293l2.267.384c-.11734.69167-11.58834,69.99825.00056,139.53164Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#4299e1"
      />
      <rect
        x="251.0257"
        y="571.20214"
        width="29.84136"
        height="2.29972"
        transform="translate(-339.58156 -31.50095) rotate(-28.1416)"
        fill="#4299e1"
      />
      <rect
        x="237.02319"
        y="564.48509"
        width="2.29972"
        height="29.84239"
        transform="translate(-486.12468 291.37147) rotate(-61.84204)"
        fill="#4299e1"
      />
      <ellipse
        cx="81.9552"
        cy="260.90342"
        rx="56.91484"
        ry="116.12927"
        fill="#4299e1"
      />
      <path
        d="M189.364,675.40405c-22.76459-136.58529-.22963-272.67316.00056-274.03181l2.267.384c-.22962,1.35528-22.69834,137.0771.00057,273.27052Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <rect
        x="179.27648"
        y="475.12522"
        width="58.36761"
        height="2.29972"
        transform="translate(-301.0624 -69.97216) rotate(-28.1416)"
        fill="#3f3d56"
      />
      <rect
        x="152.98936"
        y="460.88882"
        width="2.29972"
        height="58.36761"
        transform="translate(-451.74248 170.111) rotate(-61.84258)"
        fill="#3f3d56"
      />
      <ellipse
        cx="216.75351"
        cy="191.008"
        rx="77.88347"
        ry="158.91374"
        fill="#4299e1"
      />
      <path
        d="M326.9161,675.40405c-31.1399-186.83717-.3144-372.9922.00056-374.85051l2.267.384c-.3144,1.85494-31.07366,187.64393.00056,374.08922Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <rect
        x="312.69421"
        y="401.83114"
        width="79.87126"
        height="2.29972"
        transform="translate(-249.45002 -10.63875) rotate(-28.1416)"
        fill="#3f3d56"
      />
      <rect
        x="277.14586"
        y="381.92603"
        width="2.29972"
        height="79.87126"
        transform="translate(-326.03583 243.55793) rotate(-61.84329)"
        fill="#3f3d56"
      />
      <ellipse
        cx="871.02934"
        cy="352.47204"
        rx="29.09932"
        ry="59.37437"
        fill="#3f3d56"
      />
      <path
        d="M969.41153,674.25425c11.65459-69.92526.11734-139.59789-.00056-140.29293l-2.267.384c.11733.69167,11.58833,69.99825-.00056,139.53164Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#4299e1"
      />
      <rect
        x="957.94412"
        y="557.43132"
        width="2.29972"
        height="29.84136"
        transform="translate(-99.02545 923.51928) rotate(-61.8584)"
        fill="#4299e1"
      />
      <rect
        x="971.94611"
        y="578.25643"
        width="29.84239"
        height="2.29972"
        transform="translate(-257.69773 309.6834) rotate(-28.15796)"
        fill="#4299e1"
      />
      <ellipse
        cx="940.94638"
        cy="260.90342"
        rx="56.91484"
        ry="116.12927"
        fill="#4299e1"
      />
      <path
        d="M1035.67632,675.40405c22.76459-136.58529.22962-272.67316-.00056-274.03181l-2.267.384c.22962,1.35528,22.69834,137.0771-.00056,273.27052Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <rect
        x="1015.43021"
        y="447.09128"
        width="2.29972"
        height="58.36761"
        transform="translate(16.06635 923.44761) rotate(-61.8584)"
        fill="#3f3d56"
      />
      <rect
        x="1041.71733"
        y="488.92276"
        width="58.36761"
        height="2.29972"
        transform="translate(-205.59609 338.75568) rotate(-28.15742)"
        fill="#3f3d56"
      />
      <ellipse
        cx="806.14806"
        cy="191.008"
        rx="77.88347"
        ry="158.91374"
        fill="#4299e1"
      />
      <path
        d="M898.12426,675.40405c31.1399-186.83717.31439-372.9922-.00056-374.85051l-2.267.384c.3144,1.85494,31.07365,187.64393-.00056,374.08922Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <rect
        x="871.26065"
        y="363.04537"
        width="2.29972"
        height="79.87126"
        transform="translate(4.52428 757.59634) rotate(-61.8584)"
        fill="#3f3d56"
      />
      <rect
        x="906.809"
        y="420.7118"
        width="79.87126"
        height="2.29972"
        transform="translate(-188.10195 272.08136) rotate(-28.15671)"
        fill="#3f3d56"
      />
      <path
        d="M690.67376,326.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,690.67376,326.06186Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M465.67376,261.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,465.67376,261.06186Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M832.67376,232.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,832.67376,232.06186Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M851.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,819.26034,674.648h45.94952A13.99045,13.99045,0,0,0,851.26034,661.648Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M384.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,352.26034,674.648h45.94952A13.99045,13.99045,0,0,0,384.26034,661.648Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M623.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,591.26034,674.648h45.94952A13.99045,13.99045,0,0,0,623.26034,661.648Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <polygon
        points="471.759 404.228 339.191 404.228 339.191 408.504 359.866 408.504 359.866 449.13 364.142 449.13 364.142 408.504 444.669 408.504 444.669 449.13 448.946 449.13 448.946 408.504 471.759 408.504 471.759 404.228"
        fill="#3f3d56"
      />
      <rect
        x="339.45191"
        y="391.43404"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <rect
        x="339.45191"
        y="380.74306"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <rect
        x="339.45191"
        y="370.05209"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <polygon
        points="678.759 404.228 546.191 404.228 546.191 408.504 566.866 408.504 566.866 449.13 571.142 449.13 571.142 408.504 651.669 408.504 651.669 449.13 655.946 449.13 655.946 408.504 678.759 408.504 678.759 404.228"
        fill="#3f3d56"
      />
      <rect
        x="546.45191"
        y="391.43404"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <rect
        x="546.45191"
        y="380.74306"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <rect
        x="546.45191"
        y="370.05209"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
    </svg>
  );
};

export const EmptyStreetDark = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="be3e1a43-0c7b-454d-900a-5620992e061d"
      data-name="Layer 1"
      width="997.86122"
      height="450.8081"
      viewBox="0 0 997.86122 450.8081"
      {...props}
    >
      <rect
        x="871.99152"
        y="181.55804"
        width="30.15944"
        height="104.39806"
        fill="#f2f2f2"
      />
      <polygon
        points="922.068 266.317 848.715 179.052 701.475 180.398 612.156 267.396 613.961 268.556 613.316 268.556 613.316 449.513 921.871 449.513 921.871 268.556 922.068 266.317"
        fill="#f2f2f2"
      />
      <polygon
        points="848.792 179.238 757.154 286.674 757.154 449.513 921.871 449.513 921.871 266.236 848.792 179.238"
        fill="#e6e6e6"
      />
      <rect
        x="823.27242"
        y="359.46083"
        width="33.6394"
        height="29.73333"
        fill="#3f3d56"
      />
      <rect
        x="823.27242"
        y="307.99568"
        width="33.6394"
        height="29.26181"
        fill="#3f3d56"
      />
      <rect
        x="823.27242"
        y="359.46083"
        width="33.6394"
        height="29.73333"
        fill="#2d3748"
      />
      <rect
        x="823.27242"
        y="307.99568"
        width="33.6394"
        height="29.26181"
        fill="#2d3748"
      />
      <rect
        x="673.77661"
        y="351.57128"
        width="33.6394"
        height="29.73333"
        fill="#2d3748"
      />
      <rect
        x="673.77661"
        y="300.10613"
        width="33.6394"
        height="29.26181"
        fill="#2d3748"
      />
      <rect
        x="633.99152"
        y="181.55804"
        width="30.15944"
        height="104.39806"
        fill="#f2f2f2"
      />
      <polygon
        points="684.068 266.317 610.715 179.052 463.475 180.398 374.156 267.396 375.961 268.556 375.316 268.556 375.316 449.513 683.871 449.513 683.871 268.556 684.068 266.317"
        fill="#f2f2f2"
      />
      <polygon
        points="610.792 179.238 519.154 286.674 519.154 449.513 683.871 449.513 683.871 266.236 610.792 179.238"
        fill="#e6e6e6"
      />
      <rect
        x="585.27242"
        y="359.46083"
        width="33.6394"
        height="29.73333"
        fill="#3f3d56"
      />
      <rect
        x="585.27242"
        y="307.99568"
        width="33.6394"
        height="29.26181"
        fill="#3f3d56"
      />
      <rect
        x="585.27242"
        y="359.46083"
        width="33.6394"
        height="29.73333"
        fill="#2d3748"
      />
      <rect
        x="585.27242"
        y="307.99568"
        width="33.6394"
        height="29.26181"
        fill="#2d3748"
      />
      <rect
        x="435.77661"
        y="351.57128"
        width="33.6394"
        height="29.73333"
        fill="#2d3748"
      />
      <rect
        x="435.77661"
        y="300.10613"
        width="33.6394"
        height="29.26181"
        fill="#2d3748"
      />
      <rect
        x="380.1536"
        y="91.46021"
        width="40.30032"
        height="139.50112"
        fill="#f2f2f2"
      />
      <polygon
        points="447.068 204.718 349.051 88.112 152.302 89.91 32.951 206.161 35.362 207.711 34.501 207.711 34.501 449.513 446.804 449.513 446.804 207.711 447.068 204.718"
        fill="#f2f2f2"
      />
      <polygon
        points="349.153 88.36 226.702 231.921 226.702 449.513 446.804 449.513 446.804 204.611 349.153 88.36"
        fill="#e6e6e6"
      />
      <rect
        x="315.05306"
        y="329.18147"
        width="44.95039"
        height="39.73094"
        fill="#3f3d56"
      />
      <rect
        x="315.05306"
        y="260.41156"
        width="44.95039"
        height="39.10088"
        fill="#3f3d56"
      />
      <rect
        x="315.05306"
        y="329.18147"
        width="44.95039"
        height="39.73094"
        fill="#2d3748"
      />
      <rect
        x="315.05306"
        y="260.41156"
        width="44.95039"
        height="39.10088"
        fill="#2d3748"
      />
      <rect
        x="115.29041"
        y="318.63912"
        width="44.95039"
        height="39.73094"
        fill="#2d3748"
      />
      <rect
        x="115.29041"
        y="249.8692"
        width="44.95039"
        height="39.10088"
        fill="#2d3748"
      />
      <rect y="448.61997" width="963.95079" height="2" fill="#3f3d56" />
      <ellipse
        cx="151.87223"
        cy="352.47204"
        rx="29.09932"
        ry="59.37437"
        fill="#3f3d56"
      />
      <path
        d="M255.62882,674.25425c-11.65458-69.92526-.11734-139.59789.00056-140.29293l2.267.384c-.11734.69167-11.58834,69.99825.00056,139.53164Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#4299e1"
      />
      <rect
        x="251.0257"
        y="571.20214"
        width="29.84136"
        height="2.29972"
        transform="translate(-339.58156 -31.50095) rotate(-28.1416)"
        fill="#4299e1"
      />
      <rect
        x="237.02319"
        y="564.48509"
        width="2.29972"
        height="29.84239"
        transform="translate(-486.12468 291.37147) rotate(-61.84204)"
        fill="#4299e1"
      />
      <ellipse
        cx="81.9552"
        cy="260.90342"
        rx="56.91484"
        ry="116.12927"
        fill="#4299e1"
      />
      <path
        d="M189.364,675.40405c-22.76459-136.58529-.22963-272.67316.00056-274.03181l2.267.384c-.22962,1.35528-22.69834,137.0771.00057,273.27052Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <rect
        x="179.27648"
        y="475.12522"
        width="58.36761"
        height="2.29972"
        transform="translate(-301.0624 -69.97216) rotate(-28.1416)"
        fill="#3f3d56"
      />
      <rect
        x="152.98936"
        y="460.88882"
        width="2.29972"
        height="58.36761"
        transform="translate(-451.74248 170.111) rotate(-61.84258)"
        fill="#3f3d56"
      />
      <ellipse
        cx="216.75351"
        cy="191.008"
        rx="77.88347"
        ry="158.91374"
        fill="#4299e1"
      />
      <path
        d="M326.9161,675.40405c-31.1399-186.83717-.3144-372.9922.00056-374.85051l2.267.384c-.3144,1.85494-31.07366,187.64393.00056,374.08922Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <rect
        x="312.69421"
        y="401.83114"
        width="79.87126"
        height="2.29972"
        transform="translate(-249.45002 -10.63875) rotate(-28.1416)"
        fill="#3f3d56"
      />
      <rect
        x="277.14586"
        y="381.92603"
        width="2.29972"
        height="79.87126"
        transform="translate(-326.03583 243.55793) rotate(-61.84329)"
        fill="#3f3d56"
      />
      <ellipse
        cx="871.02934"
        cy="352.47204"
        rx="29.09932"
        ry="59.37437"
        fill="#3f3d56"
      />
      <path
        d="M969.41153,674.25425c11.65459-69.92526.11734-139.59789-.00056-140.29293l-2.267.384c.11733.69167,11.58833,69.99825-.00056,139.53164Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#4299e1"
      />
      <rect
        x="957.94412"
        y="557.43132"
        width="2.29972"
        height="29.84136"
        transform="translate(-99.02545 923.51928) rotate(-61.8584)"
        fill="#4299e1"
      />
      <rect
        x="971.94611"
        y="578.25643"
        width="29.84239"
        height="2.29972"
        transform="translate(-257.69773 309.6834) rotate(-28.15796)"
        fill="#4299e1"
      />
      <ellipse
        cx="940.94638"
        cy="260.90342"
        rx="56.91484"
        ry="116.12927"
        fill="#4299e1"
      />
      <path
        d="M1035.67632,675.40405c22.76459-136.58529.22962-272.67316-.00056-274.03181l-2.267.384c.22962,1.35528,22.69834,137.0771-.00056,273.27052Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <rect
        x="1015.43021"
        y="447.09128"
        width="2.29972"
        height="58.36761"
        transform="translate(16.06635 923.44761) rotate(-61.8584)"
        fill="#3f3d56"
      />
      <rect
        x="1041.71733"
        y="488.92276"
        width="58.36761"
        height="2.29972"
        transform="translate(-205.59609 338.75568) rotate(-28.15742)"
        fill="#3f3d56"
      />
      <ellipse
        cx="806.14806"
        cy="191.008"
        rx="77.88347"
        ry="158.91374"
        fill="#4299e1"
      />
      <path
        d="M898.12426,675.40405c31.1399-186.83717.31439-372.9922-.00056-374.85051l-2.267.384c.3144,1.85494,31.07365,187.64393-.00056,374.08922Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <rect
        x="871.26065"
        y="363.04537"
        width="2.29972"
        height="79.87126"
        transform="translate(4.52428 757.59634) rotate(-61.8584)"
        fill="#3f3d56"
      />
      <rect
        x="906.809"
        y="420.7118"
        width="79.87126"
        height="2.29972"
        transform="translate(-188.10195 272.08136) rotate(-28.15671)"
        fill="#3f3d56"
      />
      <path
        d="M690.67376,326.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,690.67376,326.06186Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M465.67376,261.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,465.67376,261.06186Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M832.67376,232.06186l9.20569-7.3628c-7.15149-.789-10.0899,3.11127-11.29248,6.19837-5.587-2.32-11.66919.72046-11.66919.72046l18.41889,6.6867A13.93792,13.93792,0,0,0,832.67376,232.06186Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M851.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,819.26034,674.648h45.94952A13.99045,13.99045,0,0,0,851.26034,661.648Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M384.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,352.26034,674.648h45.94952A13.99045,13.99045,0,0,0,384.26034,661.648Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <path
        d="M623.26034,661.648a13.91772,13.91772,0,0,0-6.96955,1.86975A14.98175,14.98175,0,0,0,591.26034,674.648h45.94952A13.99045,13.99045,0,0,0,623.26034,661.648Z"
        transform="translate(-101.06939 -224.59595)"
        fill="#3f3d56"
      />
      <polygon
        points="471.759 404.228 339.191 404.228 339.191 408.504 359.866 408.504 359.866 449.13 364.142 449.13 364.142 408.504 444.669 408.504 444.669 449.13 448.946 449.13 448.946 408.504 471.759 408.504 471.759 404.228"
        fill="#3f3d56"
      />
      <rect
        x="339.45191"
        y="391.43404"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <rect
        x="339.45191"
        y="380.74306"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <rect
        x="339.45191"
        y="370.05209"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <polygon
        points="678.759 404.228 546.191 404.228 546.191 408.504 566.866 408.504 566.866 449.13 571.142 449.13 571.142 408.504 651.669 408.504 651.669 449.13 655.946 449.13 655.946 408.504 678.759 408.504 678.759 404.228"
        fill="#3f3d56"
      />
      <rect
        x="546.45191"
        y="391.43404"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <rect
        x="546.45191"
        y="380.74306"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
      <rect
        x="546.45191"
        y="370.05209"
        width="132.56808"
        height="4.27639"
        fill="#3f3d56"
      />
    </svg>
  );
};
