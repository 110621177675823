import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { FiFilePlus } from "@react-icons/all-files/fi/FiFilePlus";
import { FiSave } from "@react-icons/all-files/fi/FiSave";

export type ActionAlertProps = {
  fileID: string;
  name: string;
  message: string;
  action: string;
  colorMode?: string;
  onClose?: () => void;
};

export const ActionAlert = ({
  fileID,
  onClose,
  name,
  message,
  action,
  colorMode,
}: ActionAlertProps) => {
  const handleOnClick = () => {
    document
      .getElementById(fileID)
      .scrollIntoView({ block: "center", behavior: "smooth" });
    onClose();
  };

  const savedColor = useColorModeValue("gray.400", "gray.600");

  const addedColor = useColorModeValue("green.400", "green.600");

  const color = action === "saved" ? savedColor : addedColor;

  return (
    <Flex
      width="full"
      padding={1}
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={handleOnClick}
    >
      <Flex
        maxWidth="sm"
        width="full"
        marginX="auto"
        shadow="md"
        rounded="lg"
        overflow="hidden"
        background={useColorModeValue("white", "gray.700")}
        color={useColorModeValue("gray.700", "gray.200")}
      >
        <Flex width={2} bg={color}></Flex>
        <Flex alignItems="center" paddingX={2} paddingY={3}>
          <Avatar
            bg={color}
            color="white"
            boxSize={8}
            icon={
              action === "saved" ? (
                <FiSave fontSize="1.25rem" />
              ) : (
                <FiFilePlus fontSize="1.25rem" />
              )
            }
          />
          <Box mx={3}>
            <Text>
              <b>{name}</b> {action}:
            </Text>
            <Text>{message}</Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
