import { Stack, StackProps, useColorModeValue } from "@chakra-ui/react";

export const Card = ({ children, ...props }: StackProps) => {
  return (
    <Stack
      maxWidth="360px"
      minWidth="300px"
      width="100%"
      minHeight="60px"
      rounded="lg"
      boxShadow="2xl"
      background={useColorModeValue("white", "gray.700")}
      border="1px solid"
      borderColor={useColorModeValue("gray.50", "gray.800")}
      {...props}
    >
      {children}
    </Stack>
  );
};
