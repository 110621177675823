import { CopyIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  DrawerBody,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { FiImage } from "@react-icons/all-files/fi/FiImage";
import { FiShield } from "@react-icons/all-files/fi/FiShield";
import { FiZap } from "@react-icons/all-files/fi/FiZap";
import { GiClick } from "@react-icons/all-files/gi/GiClick";
import { HiCursorClick } from "@react-icons/all-files/hi/HiCursorClick";
import { ExampleCard } from "./examples/ExampleCard";
import { ExampleConnect } from "./examples/ExampleConnect";
import { ExampleDropZone } from "./examples/ExampleDropZone";
import { ExampleFile } from "./examples/ExampleFile";
import { ExampleQRBoxShadow } from "./examples/ExampleQRBoxShadow";
import { ExampleQRCode } from "./examples/ExampleQRCode";
import { KonamiCode } from "./KonamiCode";

export const InformationBody = () => {
  const color = useColorModeValue("gray.700", "gray.300");
  const colorEnabled = useColorModeValue("gray.700", "gray.200");

  const tapOrClick = isMobile ? "Tap" : "Click";
  const TapOrClick = isMobile ? GiClick : HiCursorClick;

  return (
    <DrawerBody color={color} paddingX={0} paddingY={0}>
      <Accordion paddingBottom={6} allowToggle defaultIndex={[1]}>
        <AccordionItem borderTop="none">
          <h2>
            <AccordionButton
              _focus={{ outline: "none" }}
              _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
            >
              <Box flex="1" textAlign="left" paddingX={2} paddingY={2}>
                <Text fontSize="2xl" fontWeight="bold">
                  What is WebPipe?
                </Text>
              </Box>
              <AccordionIcon fontSize="32px" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} paddingX={6} paddingBottom={6}>
            <Stack spacing={6}>
              <Text lineHeight={1.6}>
                With <b>WebPipe</b> you can{" "}
                <b> transfer files directly between two devices</b> using webRTC
                without the need for the data to be sent and stored on any
                server, totally anonymous and secure.
              </Text>

              <Text lineHeight={1.6}>
                <b>WebRTC</b> is an open source project that provides web and
                mobile applications the possibility of establishing a connection
                between them in real time in a secure and encrypted way.
              </Text>
            </Stack>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem borderBottom="none">
          <h2>
            <AccordionButton
              _focus={{ outline: "none" }}
              _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
            >
              <Box flex="1" textAlign="left" paddingX={2} paddingY={2}>
                <Text fontSize="2xl" fontWeight="bold">
                  Features
                </Text>
              </Box>
              <AccordionIcon fontSize="32px" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} paddingX={6} paddingBottom={6}>
            <List spacing={5}>
              <ListItem>
                <HStack>
                  <ListIcon as={FiShield} fontSize="2xl" />
                  <Text>
                    {
                      "Direct connection, without any third party server that can store your data."
                    }
                  </Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <ListIcon as={FiImage} fontSize="2xl" />
                  <Text>
                    {
                      "Lossless transfers, ideal for images that you don't want to lose quality."
                    }
                  </Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <ListIcon as={FiZap} fontSize="2xl" />
                  <Text>
                    {
                      "Transfers as fast as your connection allows, without limitations."
                    }
                  </Text>
                </HStack>
              </ListItem>
            </List>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem borderBottom="none">
          <h2>
            <AccordionButton
              _focus={{ outline: "none" }}
              _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
            >
              <Box flex="1" textAlign="left" paddingX={2} paddingY={2}>
                <Text fontSize="2xl" fontWeight="bold">
                  How to use it?
                </Text>
              </Box>
              <AccordionIcon fontSize="32px" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Stack paddingX={6} paddingY={6} spacing={12}>
              <Stack spacing={6}>
                <Text fontSize="lg" fontWeight="semibold">
                  1. Generate the ID
                </Text>
                <Text>
                  {tapOrClick} on the QR code to generate a unique single-use
                  identifier.
                </Text>
                <ExampleCard>
                  <ExampleQRBoxShadow>
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      animation="one-click 3s cubic-bezier(0.02, 0.01, 0.21, 1) infinite"
                    >
                      <TapOrClick fontSize="32px" />
                    </Box>

                    <ExampleQRCode isDisabled />
                  </ExampleQRBoxShadow>

                  <Box
                    position="absolute"
                    zIndex={1}
                    rounded="lg"
                    boxShadow="2xl"
                    w="200%"
                    h="200%"
                    top="70px"
                    left="-100px"
                    background={useColorModeValue("white", "gray.700")}
                    border="1px solid"
                    borderColor={useColorModeValue("gray.100", "gray.800")}
                  ></Box>
                </ExampleCard>
                <Text fontSize="lg" fontWeight="semibold">
                  2. Share the ID or link to other peer
                </Text>
                <Text>
                  When generated, scan the QR code or {tapOrClick.toLowerCase()}{" "}
                  on it to copy the link to share it.
                </Text>
                <ExampleCard>
                  <ExampleQRBoxShadow marginTop={"-260px"}>
                    <ExampleQRCode isDisabled={false} />
                  </ExampleQRBoxShadow>
                  <Text
                    position="absolute"
                    top="60%"
                    userSelect="none"
                    fontSize="xs"
                    textTransform="uppercase"
                    color={useColorModeValue("gray.600", "gray.300")}
                  >
                    YOUR ID
                  </Text>
                  <Center
                    minHeight="54px"
                    minWidth="100%"
                    position="absolute"
                    top="68%"
                  >
                    <HStack
                      spacing={3}
                      color={colorEnabled}
                      userSelect={"none"}
                    >
                      <Heading
                        fontSize="2xl"
                        fontFamily="body"
                        fontWeight={600}
                      >
                        adjective-animal-123
                      </Heading>
                      <CopyIcon fontSize={"xl"} />
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        animation="one-click 3s cubic-bezier(0.02, 0.01, 0.21, 1) infinite"
                      >
                        <TapOrClick fontSize="32px" />
                      </Box>
                    </HStack>
                  </Center>
                </ExampleCard>
                <Text fontSize="lg" fontWeight="semibold">
                  3. Use the link or ID to connect
                </Text>
                <Text>
                  Open the link in other device to connect automatically, or
                  open WebPipe and paste or introduce the ID in the connect
                  input.
                </Text>
                <ExampleCard>
                  <ExampleConnect />
                </ExampleCard>
                <Text fontSize="lg" fontWeight="semibold">
                  4. Transfer the files!
                </Text>
                <Text>
                  Drag and drop the files or click the drop zone to select them.
                </Text>
                <ExampleCard>
                  <ExampleDropZone />
                </ExampleCard>
                <Text>
                  They will begin to be sent instantly, once the transfer is
                  finished, they can be saved on the device.
                </Text>
                <ExampleCard>
                  <ExampleFile />
                </ExampleCard>
              </Stack>
              <KonamiCode showCode={true} />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </DrawerBody>
  );
};
