import { Center, useColorModeValue } from "@chakra-ui/react";

export const ExampleCard = ({ children }) => {
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Center paddingY={6}>
      <Center
        height="224px"
        width="324px"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="xl"
        boxShadow="md"
        overflow="hidden"
        position="relative"
        userSelect="none"
        pointerEvents="none"
      >
        {children}
      </Center>
    </Center>
  );
};
