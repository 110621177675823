import {
  Grid,
  GridProps,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { konamiSelector, useStore } from "../state/store";

interface HeroProps extends GridProps {
  myName: string;
}

export const Hero = ({ myName, ...props }: HeroProps) => {
  const konami = useStore(konamiSelector);

  return (
    <Grid
      textAlign="center"
      paddingTop={{ base: 3, md: 2 }}
      color={useColorModeValue("gray.700", "gray.200")}
      {...props}
    >
      <Heading
        fontFamily={konami ? "custom" : "body"}
        fontWeight={600}
        fontSize={{ base: "4xl", sm: "5xl", md: "6xl" }}
        lineHeight="110%"
      >
        Welcome to
        <Text color={useColorModeValue("blue.400", "blue.600")}>WebPipe</Text>
        <Text
          lineHeight="140%"
          fontSize={{ base: "3xl", md: "4xl" }}
          my={{ base: 1, sm: 2, md: 4 }}
          fontFamily="Caveat"
          minHeight="50px"
        >
          {myName}
        </Text>
      </Heading>
      <Text
        fontSize={
          konami
            ? { base: "xs", sm: "sm", md: "md" }
            : { base: "md", sm: "lg", md: "xl" }
        }
        py={8}
      >
        An <b>easy, secure and fast</b> way to transfer files between devices 🚀
      </Text>
    </Grid>
  );
};
