import { Box, Center, Text, useColorModeValue } from "@chakra-ui/react";
import { useSpring } from "react-spring";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDropzone } from "react-dropzone";
import { animated } from "react-spring";
import { addFileSelector } from "../state/files";
import {
  connectionStatusSelector,
  meSelector,
  peerInstanceSelector,
} from "../state/peer";
import { konamiSelector, useStore } from "../state/store";

const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / 5,
  (x - rect.left - rect.width / 2) / 5,
  1.04,
];

const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export const DropZone = ({ hasFiles }) => {
  const peer = useStore(peerInstanceSelector);
  const addFile = useStore(addFileSelector);
  const { name: userName } = useStore(meSelector);

  const connectionStatus = useStore(connectionStatusSelector);
  const [dropHeight, setDropHeight] = useState("256px");

  useEffect(() => {
    if (hasFiles && connectionStatus === "CONNECTED") {
      setDropHeight("126px");
    } else {
      setDropHeight("256px");
    }
  }, [connectionStatus, hasFiles]);

  const konami = useStore(konamiSelector);

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const id = nanoid();
        const { lastModified, name, type, size } = file;

        addFile({
          id,
          state: "TRANSFERING",
          type,
          name,
          size,
          percent: 0,
          lastModified,
          sendedAt: new Date().getTime(),
          savedAt: 0,
          senderName: userName,
          fileObject: file,
          transfer: null,
        });

        peer.send(
          JSON.stringify({
            type: "FILE-ADDED",
            file: {
              id,
              percent: 0,
              type,
              name,
              size,
              sendedAt: new Date().getTime(),
              senderName: userName,
              lastModified,
              fileObject: null,
            },
          })
        );
      });
    },
    [addFile, peer, userName]
  );

  const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
    onDrop,
  });

  const BoxAnimated = animated(Box);

  const [xys, set] = useState([0, 0, 1]);
  const config = {
    mass: 3,
    tension: 160,
    friction: 30,
  };
  const spring = useSpring({
    xys,
    config,
    onRest: () => {
      if (isMobile && xys !== [0, 0, 1]) {
        set([0, 0, 1]);
      }
    },
  });

  return (
    <Box
      rounded="lg"
      marginTop={-12}
      {...getRootProps()}
      _focus={{ outline: "none" }}
      _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
    >
      <BoxAnimated
        boxShadow="2xl"
        padding={1.5}
        rounded="lg"
        overflow="hidden"
        cursor="pointer"
        height={isDragActive ? "256px" : dropHeight}
        width="256px"
        transition="height 0.4s ease"
        backgroundColor={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.400", "gray.500")}
        border="1px solid"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        onMouseLeave={() => set([0, 0, 1])}
        onMouseMove={(e) => {
          const rect = rootRef.current.getBoundingClientRect();
          set(calc(e.clientX, e.clientY, rect));
        }}
        style={{ transform: spring.xys.to(trans) }}
      >
        <Center
          height="100%"
          padding={6}
          userSelect="none"
          border="2px dashed"
          borderColor={useColorModeValue("gray.300", "gray.600")}
          rounded="lg"
          fontSize={konami ? "xs" : undefined}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text>Drop the files here ...</Text>
          ) : (
            <Text>{`Drag 'n' drop some files or click to select`}</Text>
          )}
        </Center>
      </BoxAnimated>
    </Box>
  );
};
