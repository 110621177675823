import { DrawerHeader, HStack, Text } from "@chakra-ui/react";
import { FiMessageSquare } from "@react-icons/all-files/fi/FiMessageSquare";
import { otherSelector } from "../../state/peer";
import { useStore } from "../../state/store";

export const ChatHeader = ({ isConnected }) => {
  const { name: userName } = useStore(otherSelector);

  return (
    <DrawerHeader borderBottomWidth="1px">
      <HStack alignItems="center" spacing={3}>
        <FiMessageSquare fontSize="26px" />
        <Text>{isConnected ? `Chat with ${userName}` : `Not connected`}</Text>
      </HStack>
    </DrawerHeader>
  );
};
