export const userName = "whiteYena";

export const filesMock = {
  file2: {
    id: "file2",
    name: "file-2.jpg",
    type: "image/jpeg",
    size: 123141,
    percent: 60,
    sendedAt: new Date().getTime(),
    senderName: "White Yena",
    lastModified: new Date().getTime(),
    pause: () => {},
    resume: () => {},
    cancel: () => {},
  },
  file3: {
    id: "file3",
    name: "file-3-realy-large-name.webm",
    type: "audio/webm",
    size: 12314112,
    percent: 100,
    sendedAt: new Date().getTime(),
    senderName: "Black Hyena",
    lastModified: new Date().getTime(),
    pause: () => {},
    resume: () => {},
    cancel: () => {},
  },
};
