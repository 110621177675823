import { Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { addMessageSelector } from "../state/chat";
import {
  addFileSelector,
  markFileAsSavedSelector,
  pauseFileSelector,
  receiveFileSelector,
  removeFileSelector,
  resumeFileSelector,
  sendFileSelector,
} from "../state/files";
import {
  otherSelector,
  peerInstanceSelector,
  setOtherSelector,
} from "../state/peer";
import { useStore } from "../state/store";
import { useActionToast } from "./useActionToast";
import { useBaseToast } from "./useBaseToast";

export const useDataController = () => {
  const peer = useStore(peerInstanceSelector);
  const setOther = useStore(setOtherSelector);

  const addMessage = useStore(addMessageSelector);

  const sendFile = useStore(sendFileSelector);
  const addFile = useStore(addFileSelector);
  const receiveFile = useStore(receiveFileSelector);
  const pauseFile = useStore(pauseFileSelector);
  const resumeFile = useStore(resumeFileSelector);
  const removeFile = useStore(removeFileSelector);
  const markFileAsSaved = useStore(markFileAsSavedSelector);
  const other = useStore(otherSelector);

  const showToastConnected = useBaseToast({
    id: "connected-toast",
    type: "SUCCESS",
    title: "Start sharing!",
    content: "You can start sharing!",
    position: "bottom",
    unique: true,
  });

  const showSavedFileToast = useActionToast({
    fileID: "",
    message: "",
    name: "",
    action: "saved",
    position: "top-right",
    unique: false,
  });

  useEffect(() => {
    if (peer) {
      peer.removeAllListeners("data");

      peer.on("data", (data) => {
        data = JSON.parse(data);

        switch (data.type) {
          case "CONNECTION": {
            setOther({
              id: data.id,
              name: data.name,
            });

            showToastConnected({
              content: (
                <Text>
                  {`Connected with `}
                  <Text as="b">{data.name}</Text>
                </Text>
              ),
            });
            break;
          }

          case "MESSAGE": {
            addMessage(data);
            break;
          }

          case "SEND-FILE": {
            sendFile(peer, data.id);
            break;
          }

          case "FILE-ADDED": {
            const { file } = data;
            addFile(file);
            receiveFile(peer, file.id);
            break;
          }

          case "FILE-SAVED": {
            const file = markFileAsSaved(data.id);

            showSavedFileToast({
              fileID: file.id,
              message: file.name,
              name: other.name,
              action: "saved",
            });
            break;
          }

          case "FILE-PAUSED": {
            pauseFile(data.id);
            break;
          }

          case "FILE-RESUMED": {
            resumeFile(data.id);
            break;
          }

          case "FILE-CANCELED": {
            removeFile(data.id);
            break;
          }

          default: {
            console.log("-- ALERT -- data type unknown: ", data);

            break;
          }
        }
      });
    }
  }, [peer, showToastConnected, other]);

  return;
};
