import { Text } from "@chakra-ui/react";
import { useEffect } from "react";
import {
  resetDestinationRoomSelector,
  resetRoomSelector,
} from "../state/firestore";
import {
  connectionStatusSelector,
  initPeerSelector,
  meSelector,
  otherSelector,
  peerInstanceSelector,
  setConnectionStatusSelector,
  setMeSelector,
  setOtherSelector,
} from "../state/peer";
import { useStore } from "../state/store";
import { useBaseToast } from "./useBaseToast";

export const useConnectionController = () => {
  const showToastDisconnected = useBaseToast({
    id: "disconnected-toast",
    type: "WARNING",
    title: "Disconnected!",
    content: "You have been disconnected",
    position: "bottom",
    unique: true,
  });

  const peer = useStore(peerInstanceSelector);
  const initPeer = useStore(initPeerSelector);
  const connectionStatus = useStore(connectionStatusSelector);
  const setConnectionStatus = useStore(setConnectionStatusSelector);
  const setOther = useStore(setOtherSelector);
  const setMe = useStore(setMeSelector);

  const resetRoom = useStore(resetRoomSelector);
  const resetDestinationRoom = useStore(resetDestinationRoomSelector);

  const me = useStore(meSelector);
  const other = useStore(otherSelector);

  useEffect(() => {
    if (peer && !peer.readable && connectionStatus === "DISCONNECTED") {
      console.log("--- init peer ---");
      initPeer();
    }
  }, [peer, connectionStatus]);

  useEffect(() => {
    if (peer) {
      peer.removeAllListeners("close");
      peer.removeAllListeners("connect");
      peer.removeAllListeners("error");

      peer.on("close", () => {
        console.log("--- CLOSE ---");
        if (connectionStatus === "CONNECTED") {
          setConnectionStatus("DISCONNECTED");
          showToastDisconnected({
            content: (
              <Text>
                {`Lost connection with `}
                <Text as="b">{other.name}</Text>
              </Text>
            ),
          });
        }
      });

      peer.on("connect", () => {
        console.log("--- CONNECT ---  ");
        setConnectionStatus("CONNECTED");
        peer.send(
          JSON.stringify({
            from: me.id,
            name: me.name,
            type: "CONNECTION",
          })
        );
      });

      peer.on("error", (err) => {
        console.log("--- ERROR ---: ", err);
        // peer.removeAllListeners("signal");
        //initPeer();
        const otherName = other.name;

        if (
          connectionStatus === "CONNECTED" ||
          connectionStatus === "CONNECTING"
        ) {
          setOther({ id: "", name: "" });
          setConnectionStatus("DISCONNECTED");
          const content =
            connectionStatus === "CONNECTED" ? (
              <Text>
                {`Lost connection with `}
                <Text as="b">{otherName}</Text>
              </Text>
            ) : (
              <Text>{`Unable to connect`}</Text>
            );
          showToastDisconnected({
            content,
          });
        }
      });
    }
  }, [
    me,
    other,
    peer,
    initPeer,
    setConnectionStatus,
    showToastDisconnected,
    connectionStatus,
  ]);

  return;
};
