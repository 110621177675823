import { HStack, Link, Stack, Text } from "@chakra-ui/react";
import { Chrome, Firefox, Opera } from "../common/icons";

export const SupportedBrowsers = () => {
  return (
    <Stack fontWeight="semibold" fontSize="16px">
      <Text fontWeight="semibold" fontSize="20px" paddingTop={3}>
        Browser not supported
      </Text>
      <Text fontSize="sm" fontWeight="normal" paddingBottom={3}>
        The browser has to accept <Text as="b">webRTC</Text> API, try any of the
        following:
      </Text>
      <Stack paddingX={4}>
        <Link href="https://www.google.com/chrome/" isExternal>
          <HStack paddingY={2}>
            <Chrome style={{ height: "46px", width: "46px" }} />
            <Text paddingLeft={1}>Google Chrome</Text>
          </HStack>
        </Link>
        <Link href="https://www.mozilla.org/firefox/" isExternal>
          <HStack paddingY={2}>
            <Firefox style={{ height: "46px", width: "46px" }} />
            <Text paddingLeft={1}>Mozilla Firefox</Text>
          </HStack>
        </Link>
        <Link href="https://www.opera.com/" isExternal paddingBottom={3}>
          <HStack paddingY={2}>
            <Opera style={{ height: "46px", width: "46px" }} />
            <Text paddingLeft={1}>Opera</Text>
          </HStack>
        </Link>
      </Stack>
    </Stack>
  );
};
