import { Box, BoxProps } from "@chakra-ui/react";

export const ExampleQRBoxShadow = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      boxShadow="2xl"
      rounded="lg"
      overflow="hidden"
      marginTop={24}
      width="254px"
      height="254px"
      zIndex={2}
      {...props}
    >
      {children}
    </Box>
  );
};
