import {
  Box,
  Center,
  HStack,
  IconButton,
  Progress,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { CgClose } from "@react-icons/all-files/cg/CgClose";
import { IoPauseOutline } from "@react-icons/all-files/io5/IoPauseOutline";
import { getColorFromStatus, getFileTypeIcon } from "../../../common/utils";
import { Card } from "../../common/Card";
import { filesMock } from "../../files/mocks";

export const ExampleFile = () => {
  const status = "Downloading";

  const { id, name, type, percent, senderName } = filesMock["file2"];

  return (
    <Center>
      <Card padding={6}>
        <Stack id={id} py={2} px={1} position="relative">
          <HStack spacing={2}>
            <Stack spacing={1} flex={1}>
              <Text
                color={useColorModeValue("gray.500", "gray.400")}
                fontSize="xs"
              >
                {senderName}:
              </Text>
              <HStack
                fontSize="sm"
                paddingTop={1}
                color={useColorModeValue("gray.700", "gray.300")}
              >
                <Box>{getFileTypeIcon({ type })}</Box>
                <Text noOfLines={2} wordBreak="break-all">
                  {name}
                </Text>
              </HStack>
              <Text
                fontSize="sm"
                color={useColorModeValue("gray.500", "gray.400")}
              >
                62% · {status}
              </Text>
            </Stack>
            <HStack spacing={3}>
              <IconButton
                rounded={"full"}
                aria-label={`Pause ${name}`}
                size="sm"
                icon={<IoPauseOutline fontSize="18px" />}
                _focus={{ outline: "none" }}
                _focusVisible={{
                  boxShadow: "var(--chakra-shadows-outline)",
                }}
              ></IconButton>

              <IconButton
                rounded={"full"}
                aria-label={`Cancel ${name}`}
                size="sm"
                icon={<CgClose fontSize="18px" />}
                _focus={{ outline: "none" }}
                _focusVisible={{
                  boxShadow: "var(--chakra-shadows-outline)",
                }}
              ></IconButton>
            </HStack>
          </HStack>
          <Progress
            value={Number(percent)}
            size="xs"
            height="2px"
            colorScheme={getColorFromStatus(status)}
          />
        </Stack>
      </Card>
    </Center>
  );
};
