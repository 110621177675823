export const HappyBird = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="a780dc9c-2107-4fd6-8fec-765248a5097a"
      data-name="Layer 1"
      width="887.77227"
      height="772.83071"
      viewBox="0 0 887.77227 772.83071"
      {...props}
    >
      <title>happy_music</title>
      <polygon
        points="887.772 763.25 252.263 772.831 0 728.121 393.817 728.121 887.772 763.25"
        fill="#e6e6e6"
      />
      <circle cx="731.82227" cy="101.66024" r="101.66024" fill="#ff6584" />
      <path
        d="M335.9542,797.01953s1.487-31.15875,31.97119-27.537"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <circle cx="171.2269" cy="688.52637" r="15.25711" fill="#4299e1" />
      <rect
        x="168.74434"
        y="714.21843"
        width="4.30672"
        height="30.14703"
        fill="#3f3d56"
      />
      <path
        d="M838.40061,802.34205s1.487-31.15874,31.97119-27.537"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <circle cx="673.67331" cy="693.8489" r="15.25711" fill="#4299e1" />
      <rect
        x="671.19075"
        y="719.54095"
        width="4.30672"
        height="30.14703"
        fill="#3f3d56"
      />
      <path
        d="M911.85146,807.66458s1.487-31.15874,31.9712-27.537"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <circle cx="747.12416" cy="699.17142" r="15.25711" fill="#4299e1" />
      <rect
        x="744.64161"
        y="724.86348"
        width="4.30672"
        height="30.14703"
        fill="#3f3d56"
      />
      <path
        d="M456.24328,782.11645s1.487-31.15874,31.97119-27.53695"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <circle cx="291.51598" cy="673.6233" r="15.25711" fill="#4299e1" />
      <rect
        x="289.03342"
        y="699.31536"
        width="4.30672"
        height="30.14703"
        fill="#3f3d56"
      />
      <path
        d="M229.50369,787.439s1.487-31.15874,31.97119-27.537"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <circle cx="64.77639" cy="678.94583" r="15.25711" fill="#4299e1" />
      <rect
        x="62.29383"
        y="704.63788"
        width="4.30672"
        height="30.14703"
        fill="#3f3d56"
      />
      <circle cx="442.27688" cy="503.48191" r="166.22823" fill="#3f3d56" />
      <path
        d="M493.73979,580.41025a43.98767,43.98767,0,0,1,67.51907,0,48.89067,48.89067,0,1,0-67.51907,0Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#fff"
      />
      <path
        d="M625.74457,580.41025a43.98759,43.98759,0,0,1,67.519,0,48.89064,48.89064,0,1,0-67.519,0Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#fff"
      />
      <circle cx="354.70738" cy="464.81392" r="16.81788" fill="#3f3d56" />
      <circle cx="486.70738" cy="464.81392" r="16.81788" fill="#3f3d56" />
      <circle cx="322.49478" cy="545.03897" r="19.55626" fill="#ff6584" />
      <circle cx="547.39179" cy="545.03897" r="19.55626" fill="#ff6584" />
      <polygon
        points="434.943 505.926 420.276 569.484 444.721 545.039 434.943 505.926"
        fill="#ff6584"
      />
      <polygon
        points="510.307 750.38 493.655 736.404 494.043 750.38 488.871 750.38 488.458 735.602 465.975 750.38 456.563 750.38 488.29 729.526 487.061 685.737 486.143 652.666 491.302 652.524 492.233 685.737 493.461 729.487 518.348 750.38 510.307 750.38"
        fill="#3f3d56"
      />
      <polygon
        points="432.076 750.38 415.424 736.404 415.812 750.38 410.654 750.38 410.24 735.602 387.757 750.38 378.345 750.38 410.059 729.526 408.831 685.737 407.913 652.666 413.084 652.524 414.015 685.737 415.23 729.487 440.118 750.38 432.076 750.38"
        fill="#3f3d56"
      />
      <path
        d="M600.83528,388.61566c-4.56112,0-8.58481,3.543-11.27493,8.985-2.37112-8.18315-7.3527-13.874-13.1704-13.874a8.84714,8.84714,0,0,0-1.17422.19724c-2.23625-8.737-7.43356-14.86444-13.493-14.86444-8.1005,0-14.6672,10.9446-14.6672,24.44533s6.5667,24.44533,14.6672,24.44533a8.84716,8.84716,0,0,0,1.17422-.19725c2.23625,8.737,7.43356,14.86444,13.493,14.86444,4.56112,0,8.58481-3.543,11.27493-8.985,2.37113,8.18316,7.3527,13.874,13.1704,13.874,8.1005,0,14.6672-10.9446,14.6672-24.44533S608.93578,388.61566,600.83528,388.61566Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <path
        d="M393.47351,507.83307h0a48.89061,48.89061,0,0,1,48.89061,48.89061v24.44543a48.89061,48.89061,0,0,1-48.89061,48.89061h0a0,0,0,0,1,0,0V507.83307A0,0,0,0,1,393.47351,507.83307Z"
        transform="translate(679.72382 1074.30814) rotate(180)"
        fill="#4299e1"
      />
      <path
        d="M598.30346,444.24843h0A48.89061,48.89061,0,0,1,647.19412,493.139v24.44543a48.89061,48.89061,0,0,1-48.89061,48.89061h0a0,0,0,0,1,0,0V444.24843A0,0,0,0,1,598.30346,444.24843Z"
        fill="#4299e1"
      />
      <path
        d="M780.03662,560.56514H772.2795c0-98.37742-80.03569-178.41374-178.41374-178.41374-98.37742,0-178.41374,80.03632-178.41374,178.41374H407.6949c0-102.655,83.51592-186.17086,186.17086-186.17086C696.52134,374.39428,780.03662,457.91019,780.03662,560.56514Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#4299e1"
      />
      <polygon
        points="131.69 367.997 131.69 367.997 131.69 367.997 131.69 367.997"
        fill="#3f3d56"
      />
      <path
        d="M327.66117,411.058l.00139-.0029-3.64431-1.51771-15.37654-6.50027-5.51164-2.32988-.423,1.06789h0l-7.40381,18.694L316.14,429.28836l.1094.09906-1.40416,3.31082a5.86169,5.86169,0,0,0-.4899.95547c-.98358,2.48353.87418,4.95394,2.63035,5.6494s3.9773-.75381,4.96087-3.23734c.97022-2.44974.02206-5.5618-1.68937-6.31373l.00151-.0029-3.64431-1.51771-12.46676-4.84631,7.12325-17.98534-1.0558-.41806-7.12577,17.992-1.54154-.59929,7.15363-18.06223.18843.07978,14.6541,6.20232.10928.09906-1.404,3.31082a5.85137,5.85137,0,0,0-.48989.95547c-.98371,2.48353.87405,4.95394,2.63022,5.6494s3.9773-.75381,4.96087-3.23734C330.32064,414.922,329.37248,411.80993,327.66117,411.058Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <path
        d="M399.78108,456.95208c-7.564-4.32464-6.05044-13.815-6.05044-13.815-.19032-6.16526-5.284-5.29259-5.284-5.29259l-1.85385.4231a5.62911,5.62911,0,0,0-2.75664-.50981l-2.82.47452c6.42137,1.50877,5.89378,6.88051,5.89378,6.88051l.89964,6.281-16.82582-6.78775,1.93187-4.555a8.12868,8.12868,0,0,0,.68046-1.32715c1.3661-3.44946-1.21409-6.88051-3.65325-7.84645-2.43916-.96618-5.524,1.0471-6.89022,4.49643-1.34744,3.40232-.0305,7.7247,2.3464,8.769l-.002.00415,5.06157,2.10782.03769-.11936Zm-8.54443-11.036.53956,6.012a13.8665,13.8665,0,0,1-2.52575-9.20675,5.82316,5.82316,0,0,0-1.12688-3.22524A6.83065,6.83065,0,0,1,391.23665,445.91606Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <path
        d="M271.4328,368.0405l.002-.004-5.06157-2.10794-21.1545-8.22341,5.33923-4.75027a6.74419,6.74419,0,0,1,9.21683-1.5099l-1.65686-2.46248s-3.11483-4.12374-7.475.23935c0,0-5.39481,7.95307-13.86878,5.92579l28.94032,12.249.15187.13763-1.95014,4.59827a8.12429,8.12429,0,0,0-.68047,1.32715c-1.36609,3.44933,1.2141,6.88038,3.65326,7.84644s5.524-1.0471,6.89022-4.49643C275.12664,373.40733,273.8097,369.085,271.4328,368.0405Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <path
        d="M348.87976,341.126l-1.95027,4.59826a8.13218,8.13218,0,0,0-.68034,1.32715c-1.36622,3.44933,1.214,6.88039,3.65313,7.84645s5.52412-1.0471,6.89022-4.49643c1.34756-3.40232.03062-7.7247-2.34627-8.76915l.002-.004-5.06157-2.10794-17.31509-6.73091,9.89338-24.97992-1.46629-.58077-9.89716,24.98925-2.141-.83221,9.9356-25.0868.26178.11078,20.353,8.61438.152.13763L357.2127,319.76a8.13743,8.13743,0,0,0-.68034,1.32715c-1.36622,3.44946,1.214,6.88051,3.65313,7.84657s5.52412-1.0471,6.89022-4.49656c1.34756-3.40231.03062-7.72469-2.34628-8.769l.002-.00416-5.06157-2.10781L338.98511,304.812h0l-.00088-.00038-.67088-.28358-7.65513-3.23608-10.87066,27.44744,28.9402,12.249Zm-18.809-38.35074Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <path
        d="M257.16744,200.55646l.00353-.00706-8.71319-3.62856-36.41622-14.15627,9.19111-8.17728s6.11371-8.4828,15.86619-2.5991l-2.852-4.23907s-5.36217-7.09893-12.86781.41189c0,0-9.2869,13.69082-23.87434,10.201l49.81893,21.08581.26152.237-3.35707,7.91563a14.00544,14.00544,0,0,0-1.17137,2.28464c-2.35169,5.9379,2.08991,11.84429,6.28877,13.50732s9.50936-1.80256,11.86117-7.74045C263.52617,209.795,261.25917,202.35435,257.16744,200.55646Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
      <path
        d="M283.90741,319.4701c-18.08491-10.33993-14.46594-33.03029-14.46594-33.03029-.455-14.7407-12.63376-12.65406-12.63376-12.65406l-4.43241,1.01143a13.4583,13.4583,0,0,0-6.59088-1.219l-6.7425,1.13469c15.353,3.60738,14.09161,16.45074,14.09161,16.45074l2.15092,15.0171-40.229-16.2288,4.61893-10.89082a19.45075,19.45075,0,0,0,1.627-3.17306c3.26633-8.24724-2.90271-16.45062-8.73461-18.76034s-13.20748,2.50356-16.47381,10.75068c-3.22184,8.13468-.07322,18.46919,5.60983,20.96607l-.00479.00983,12.10177,5.03977.09011-.28534Zm-20.429-26.3861,1.29451,14.42385c-7.59564-10.08294-6.04314-22.06233-6.04314-22.06233a13.925,13.925,0,0,0-2.69425-7.71121C264.76078,282.95569,263.47837,293.084,263.47837,293.084Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#3f3d56"
      />
    </svg>
  );
};

export const HappyBirdDark = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="a780dc9c-2107-4fd6-8fec-765248a5097a"
      data-name="Layer 1"
      width="887.77227"
      height="772.83071"
      viewBox="0 0 887.77227 772.83071"
      {...props}
    >
      <title>happy_music</title>
      <polygon
        points="887.772 763.25 252.263 772.831 0 728.121 393.817 728.121 887.772 763.25"
        fill="#e6e6e6"
      />
      <circle cx="731.82227" cy="101.66024" r="101.66024" fill="#ff6584" />
      <path
        d="M335.9542,797.01953s1.487-31.15875,31.97119-27.537"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <circle cx="171.2269" cy="688.52637" r="15.25711" fill="#4299e1" />
      <rect
        x="168.74434"
        y="714.21843"
        width="4.30672"
        height="30.14703"
        fill="#171d26"
      />
      <path
        d="M838.40061,802.34205s1.487-31.15874,31.97119-27.537"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <circle cx="673.67331" cy="693.8489" r="15.25711" fill="#4299e1" />
      <rect
        x="671.19075"
        y="719.54095"
        width="4.30672"
        height="30.14703"
        fill="#171d26"
      />
      <path
        d="M911.85146,807.66458s1.487-31.15874,31.9712-27.537"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <circle cx="747.12416" cy="699.17142" r="15.25711" fill="#4299e1" />
      <rect
        x="744.64161"
        y="724.86348"
        width="4.30672"
        height="30.14703"
        fill="#171d26"
      />
      <path
        d="M456.24328,782.11645s1.487-31.15874,31.97119-27.53695"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <circle cx="291.51598" cy="673.6233" r="15.25711" fill="#4299e1" />
      <rect
        x="289.03342"
        y="699.31536"
        width="4.30672"
        height="30.14703"
        fill="#171d26"
      />
      <path
        d="M229.50369,787.439s1.487-31.15874,31.97119-27.537"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <circle cx="64.77639" cy="678.94583" r="15.25711" fill="#4299e1" />
      <rect
        x="62.29383"
        y="704.63788"
        width="4.30672"
        height="30.14703"
        fill="#171d26"
      />
      <circle cx="442.27688" cy="503.48191" r="166.22823" fill="#171d26" />
      <path
        d="M493.73979,580.41025a43.98767,43.98767,0,0,1,67.51907,0,48.89067,48.89067,0,1,0-67.51907,0Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#fff"
      />
      <path
        d="M625.74457,580.41025a43.98759,43.98759,0,0,1,67.519,0,48.89064,48.89064,0,1,0-67.519,0Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#fff"
      />
      <circle cx="354.70738" cy="464.81392" r="16.81788" fill="#171d26" />
      <circle cx="486.70738" cy="464.81392" r="16.81788" fill="#171d26" />
      <circle cx="322.49478" cy="545.03897" r="19.55626" fill="#ff6584" />
      <circle cx="547.39179" cy="545.03897" r="19.55626" fill="#ff6584" />
      <polygon
        points="434.943 505.926 420.276 569.484 444.721 545.039 434.943 505.926"
        fill="#ff6584"
      />
      <polygon
        points="510.307 750.38 493.655 736.404 494.043 750.38 488.871 750.38 488.458 735.602 465.975 750.38 456.563 750.38 488.29 729.526 487.061 685.737 486.143 652.666 491.302 652.524 492.233 685.737 493.461 729.487 518.348 750.38 510.307 750.38"
        fill="#171d26"
      />
      <polygon
        points="432.076 750.38 415.424 736.404 415.812 750.38 410.654 750.38 410.24 735.602 387.757 750.38 378.345 750.38 410.059 729.526 408.831 685.737 407.913 652.666 413.084 652.524 414.015 685.737 415.23 729.487 440.118 750.38 432.076 750.38"
        fill="#171d26"
      />
      <path
        d="M600.83528,388.61566c-4.56112,0-8.58481,3.543-11.27493,8.985-2.37112-8.18315-7.3527-13.874-13.1704-13.874a8.84714,8.84714,0,0,0-1.17422.19724c-2.23625-8.737-7.43356-14.86444-13.493-14.86444-8.1005,0-14.6672,10.9446-14.6672,24.44533s6.5667,24.44533,14.6672,24.44533a8.84716,8.84716,0,0,0,1.17422-.19725c2.23625,8.737,7.43356,14.86444,13.493,14.86444,4.56112,0,8.58481-3.543,11.27493-8.985,2.37113,8.18316,7.3527,13.874,13.1704,13.874,8.1005,0,14.6672-10.9446,14.6672-24.44533S608.93578,388.61566,600.83528,388.61566Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <path
        d="M393.47351,507.83307h0a48.89061,48.89061,0,0,1,48.89061,48.89061v24.44543a48.89061,48.89061,0,0,1-48.89061,48.89061h0a0,0,0,0,1,0,0V507.83307A0,0,0,0,1,393.47351,507.83307Z"
        transform="translate(679.72382 1074.30814) rotate(180)"
        fill="#4299e1"
      />
      <path
        d="M598.30346,444.24843h0A48.89061,48.89061,0,0,1,647.19412,493.139v24.44543a48.89061,48.89061,0,0,1-48.89061,48.89061h0a0,0,0,0,1,0,0V444.24843A0,0,0,0,1,598.30346,444.24843Z"
        fill="#4299e1"
      />
      <path
        d="M780.03662,560.56514H772.2795c0-98.37742-80.03569-178.41374-178.41374-178.41374-98.37742,0-178.41374,80.03632-178.41374,178.41374H407.6949c0-102.655,83.51592-186.17086,186.17086-186.17086C696.52134,374.39428,780.03662,457.91019,780.03662,560.56514Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#4299e1"
      />
      <polygon
        points="131.69 367.997 131.69 367.997 131.69 367.997 131.69 367.997"
        fill="#171d26"
      />
      <path
        d="M327.66117,411.058l.00139-.0029-3.64431-1.51771-15.37654-6.50027-5.51164-2.32988-.423,1.06789h0l-7.40381,18.694L316.14,429.28836l.1094.09906-1.40416,3.31082a5.86169,5.86169,0,0,0-.4899.95547c-.98358,2.48353.87418,4.95394,2.63035,5.6494s3.9773-.75381,4.96087-3.23734c.97022-2.44974.02206-5.5618-1.68937-6.31373l.00151-.0029-3.64431-1.51771-12.46676-4.84631,7.12325-17.98534-1.0558-.41806-7.12577,17.992-1.54154-.59929,7.15363-18.06223.18843.07978,14.6541,6.20232.10928.09906-1.404,3.31082a5.85137,5.85137,0,0,0-.48989.95547c-.98371,2.48353.87405,4.95394,2.63022,5.6494s3.9773-.75381,4.96087-3.23734C330.32064,414.922,329.37248,411.80993,327.66117,411.058Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <path
        d="M399.78108,456.95208c-7.564-4.32464-6.05044-13.815-6.05044-13.815-.19032-6.16526-5.284-5.29259-5.284-5.29259l-1.85385.4231a5.62911,5.62911,0,0,0-2.75664-.50981l-2.82.47452c6.42137,1.50877,5.89378,6.88051,5.89378,6.88051l.89964,6.281-16.82582-6.78775,1.93187-4.555a8.12868,8.12868,0,0,0,.68046-1.32715c1.3661-3.44946-1.21409-6.88051-3.65325-7.84645-2.43916-.96618-5.524,1.0471-6.89022,4.49643-1.34744,3.40232-.0305,7.7247,2.3464,8.769l-.002.00415,5.06157,2.10782.03769-.11936Zm-8.54443-11.036.53956,6.012a13.8665,13.8665,0,0,1-2.52575-9.20675,5.82316,5.82316,0,0,0-1.12688-3.22524A6.83065,6.83065,0,0,1,391.23665,445.91606Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <path
        d="M271.4328,368.0405l.002-.004-5.06157-2.10794-21.1545-8.22341,5.33923-4.75027a6.74419,6.74419,0,0,1,9.21683-1.5099l-1.65686-2.46248s-3.11483-4.12374-7.475.23935c0,0-5.39481,7.95307-13.86878,5.92579l28.94032,12.249.15187.13763-1.95014,4.59827a8.12429,8.12429,0,0,0-.68047,1.32715c-1.36609,3.44933,1.2141,6.88038,3.65326,7.84644s5.524-1.0471,6.89022-4.49643C275.12664,373.40733,273.8097,369.085,271.4328,368.0405Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <path
        d="M348.87976,341.126l-1.95027,4.59826a8.13218,8.13218,0,0,0-.68034,1.32715c-1.36622,3.44933,1.214,6.88039,3.65313,7.84645s5.52412-1.0471,6.89022-4.49643c1.34756-3.40232.03062-7.7247-2.34627-8.76915l.002-.004-5.06157-2.10794-17.31509-6.73091,9.89338-24.97992-1.46629-.58077-9.89716,24.98925-2.141-.83221,9.9356-25.0868.26178.11078,20.353,8.61438.152.13763L357.2127,319.76a8.13743,8.13743,0,0,0-.68034,1.32715c-1.36622,3.44946,1.214,6.88051,3.65313,7.84657s5.52412-1.0471,6.89022-4.49656c1.34756-3.40231.03062-7.72469-2.34628-8.769l.002-.00416-5.06157-2.10781L338.98511,304.812h0l-.00088-.00038-.67088-.28358-7.65513-3.23608-10.87066,27.44744,28.9402,12.249Zm-18.809-38.35074Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <path
        d="M257.16744,200.55646l.00353-.00706-8.71319-3.62856-36.41622-14.15627,9.19111-8.17728s6.11371-8.4828,15.86619-2.5991l-2.852-4.23907s-5.36217-7.09893-12.86781.41189c0,0-9.2869,13.69082-23.87434,10.201l49.81893,21.08581.26152.237-3.35707,7.91563a14.00544,14.00544,0,0,0-1.17137,2.28464c-2.35169,5.9379,2.08991,11.84429,6.28877,13.50732s9.50936-1.80256,11.86117-7.74045C263.52617,209.795,261.25917,202.35435,257.16744,200.55646Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
      <path
        d="M283.90741,319.4701c-18.08491-10.33993-14.46594-33.03029-14.46594-33.03029-.455-14.7407-12.63376-12.65406-12.63376-12.65406l-4.43241,1.01143a13.4583,13.4583,0,0,0-6.59088-1.219l-6.7425,1.13469c15.353,3.60738,14.09161,16.45074,14.09161,16.45074l2.15092,15.0171-40.229-16.2288,4.61893-10.89082a19.45075,19.45075,0,0,0,1.627-3.17306c3.26633-8.24724-2.90271-16.45062-8.73461-18.76034s-13.20748,2.50356-16.47381,10.75068c-3.22184,8.13468-.07322,18.46919,5.60983,20.96607l-.00479.00983,12.10177,5.03977.09011-.28534Zm-20.429-26.3861,1.29451,14.42385c-7.59564-10.08294-6.04314-22.06233-6.04314-22.06233a13.925,13.925,0,0,0-2.69425-7.71121C264.76078,282.95569,263.47837,293.084,263.47837,293.084Z"
        transform="translate(-156.11386 -63.58465)"
        fill="#171d26"
      />
    </svg>
  );
};
