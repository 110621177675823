import { Box, Center, Flex, Link, Text } from "@chakra-ui/react";
import { CoffeMug } from "../../common/icons";
import { konamiSelector, useStore } from "../../state/store";
import { BlankLink } from "../common/BlankLink";
import { HandwritedBuyMeACoffe } from "../handwrited/HandwritedBuyMeACoffee";
import { KonamiCode } from "../information/KonamiCode";

export const Footer = () => {
  const konami = useStore(konamiSelector);

  return (
    <Center
      padding={6}
      paddingBottom={2}
      fontFamily={konami ? "custom" : undefined}
      fontSize={konami ? "10px" : "xs"}
    >
      <Flex align="flex-end">
        <Text as="span">Made with a lot of</Text>
        <Box marginX={2} position="relative">
          <Link target="_blank" href="https://www.buymeacoffee.com/klykov">
            <CoffeMug width="24px" height="auto" />
            <HandwritedBuyMeACoffe />
          </Link>
        </Box>
        <Text as="span">
          by
          <BlankLink marginLeft={1} fontWeight="bold" href="https://klykov.me/">
            Konstantin Klykov
          </BlankLink>
        </Text>
      </Flex>
      <KonamiCode showCode={false} />
    </Center>
  );
};
