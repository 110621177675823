import {
  Box,
  BoxProps,
  useClipboard,
  useColorModeValue,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import QRCode from "react-qr-code";
import { animated, useSpring } from "react-spring";
import { useBaseToast } from "../hooks/useBaseToast";
import { initSimplePeerFileSelector } from "../state/files";
import { initRoomSelector, roomSelector } from "../state/firestore";
import { initPeerSelector } from "../state/peer";
import { useStore } from "../state/store";

interface QRProps extends BoxProps {
  myID: string;
}

const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / 5,
  (x - rect.left - rect.width / 2) / 5,
  1.08,
];

const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export const QR = ({ myID, ...props }: QRProps) => {
  const showToast = useBaseToast({
    id: "link-toast",
    type: "INFO",
    title: "Link copied!",
    content: "Share it to connect.",
    position: "bottom",
    unique: true,
  });

  const url = `${process.env.NEXT_PUBLIC_URL}${myID}`;
  const room = useStore(roomSelector);

  const initPeer = useStore(initPeerSelector);
  const initRoom = useStore(initRoomSelector);
  const initSimplePeerFile = useStore(initSimplePeerFileSelector);

  const { onCopy } = useClipboard(url);

  const colorDisabled = useColorModeValue("#1A202C", "black");
  const colorEnabled = useColorModeValue("#CBD5E0", "#2D3748");

  const bgDisabled = useColorModeValue("white", "#CBD5E0");
  const bgEnabled = useColorModeValue("white", "#4a5568");

  const QRvalue = room ? url : "Please generate the ID before scan the QR code";
  const QRcolor = room ? colorDisabled : colorEnabled;
  const QRbackground = room ? bgDisabled : bgEnabled;

  const handleGenerateID = () => {
    if (!room) {
      initRoom();
      initPeer(true);
      initSimplePeerFile();
    }
  };

  const handleClick = () => {
    if (!room) {
      handleGenerateID();
    } else {
      onCopy();
      showToast({});
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!room) {
        handleGenerateID();
      } else {
        handleClick();
      }
    }
  };

  const BoxAnimated = animated(Box);
  const ref = useRef(null);
  const [xys, set] = useState([0, 0, 1]);
  const config = {
    mass: 3,
    tension: 160,
    friction: 30,
  };
  const spring = useSpring({
    xys,
    config,
    onRest: () => {
      if (isMobile && xys !== [0, 0, 1]) {
        set([0, 0, 1]);
      }
    },
  });

  return (
    <Box
      ref={ref}
      tabIndex={0}
      marginTop={-12}
      rounded="lg"
      cursor="pointer"
      width="256px"
      height="256px"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      zIndex={100}
      {...props}
      _focus={{ outline: "none" }}
      _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
    >
      <BoxAnimated
        boxShadow="2xl"
        rounded="lg"
        overflow="hidden"
        onMouseLeave={() => {
          set([0, 0, 1]);
          setTimeout(() => {
            document.getElementById("qr-code")?.classList.remove("qr-code");
          }, 1400);
        }}
        onMouseMove={(e) => {
          document.getElementById("qr-code")?.classList.add("qr-code");
          const rect = ref.current.getBoundingClientRect();
          set(calc(e.clientX, e.clientY, rect));
        }}
        style={{ transform: spring.xys.to(trans) }}
      >
        <QRCode
          id="qr-code"
          value={QRvalue}
          fgColor={QRcolor}
          bgColor={QRbackground}
        />
      </BoxAnimated>
    </Box>
  );
};
