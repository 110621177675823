import { Center, HStack, Stack, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/nextjs";
import dynamic from "next/dynamic";
import { useRef } from "react";
import { FiArrowDown } from "@react-icons/all-files/fi/FiArrowDown";
import { FiArrowLeft } from "@react-icons/all-files/fi/FiArrowLeft";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";
import { FiArrowUp } from "@react-icons/all-files/fi/FiArrowUp";
import { KonamiCodeLogo } from "../../common/icons";
import { roomSelector } from "../../state/firestore";
import { meSelector, peerSelector } from "../../state/peer";
import { toggleKonamiSelector, useStore } from "../../state/store";

const KeyboardEventHandler = dynamic(
  () => import("react-keyboard-event-handler") as any,
  { ssr: false }
);

export const KonamiCode = ({ showCode }) => {
  const konamiCodePosition = useRef(0);
  var konamiCode = [
    "up",
    "up",
    "down",
    "down",
    "left",
    "right",
    "left",
    "right",
    "b",
    "a",
  ];

  const toggleKonami = useStore(toggleKonamiSelector);

  const me = useStore(meSelector);
  const room = useStore(roomSelector);
  const peer = useStore(peerSelector);

  return (
    <>
      {showCode ? (
        <Center
          opacity="0.12"
          _hover={{ opacity: 1 }}
          transition="all 0.32s ease"
          onClick={() => toggleKonami()}
        >
          <Stack textAlign="center">
            <KonamiCodeLogo fontSize="100px" height="32px" margin="0 auto" />
            <HStack>
              <FiArrowUp />
              <FiArrowUp />
              <FiArrowDown />
              <FiArrowDown />
              <FiArrowLeft />
              <FiArrowRight />
              <FiArrowLeft />
              <FiArrowRight />
              <Text as="b">B</Text>
              <Text as="b">A</Text>
            </HStack>
          </Stack>
        </Center>
      ) : null}
      <KeyboardEventHandler
        // @ts-ignore: Unreachable code error
        handleKeys={["all"]}
        onKeyEvent={(key, e) => {
          const requiredKey = konamiCode[konamiCodePosition.current];

          if (key == requiredKey) {
            konamiCodePosition.current++;

            if (konamiCodePosition.current == konamiCode.length) {
              toggleKonami();
              if (process.env.NEXT_PUBLIC_ENV !== "LOCAL") {
                Sentry.captureException(
                  new Error(
                    `KONAMI ACTIVATE -> ${me.name} with ID: ${
                      me.id
                    } activated konami code on ${new Date().toUTCString()} ${
                      room ? "with" : "whitout"
                    } room and ${
                      peer.connectionStatus === "CONNECTED"
                        ? "connected."
                        : "disconnected."
                    }`
                  )
                );
              }
              konamiCodePosition.current = 0;
            }
          } else {
            konamiCodePosition.current = 0;
          }
        }}
      />
    </>
  );
};
