import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { FiInfo } from "@react-icons/all-files/fi/FiInfo";
import { RoundedIconButton } from "../common/RoundedIconButton";
import { InformationBody } from "./InformationBody";
import { InformationHeader } from "./InformationHeader";

export const Information = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonRef = useRef();

  return (
    <>
      <RoundedIconButton
        ref={buttonRef}
        size="sm"
        aria-label="information"
        icon={<FiInfo fontSize="18px" />}
        onClick={onOpen}
      />
      <Drawer
        size="md"
        placement="right"
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={buttonRef}
        blockScrollOnMount={true}
        preserveScrollBarGap={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            marginTop={2}
            _focus={{ outline: "none" }}
            _focusVisible={{ boxShadow: "var(--chakra-shadows-outline)" }}
          />
          <InformationHeader />
          <InformationBody />
        </DrawerContent>
      </Drawer>
    </>
  );
};
