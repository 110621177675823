import {
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { ArrowSVG } from "../../common/icons";

export const HandwritedFilesMessage = () => {
  const handWritedText = useBreakpointValue({
    base: {
      left: "40px",
      top: "-46px",
      transform: "rotate(-2deg)",
    },
    md: {
      left: "20px",
      top: "-46px",
      transform: "rotate(-2deg)",
    },
  });

  const arrowIcon = useBreakpointValue({
    base: {
      left: "-12px",
      top: "-10px",
      transform: "rotateY(180deg) rotateZ(-100deg)",
    },
    md: {
      left: "-24px",
      top: "-10px",
      transform: "rotateY(180deg) rotateZ(-80deg)",
    },
  });

  const color = useColorModeValue("gray.600", "gray.400");

  return (
    <>
      <Text
        fontSize="xl"
        fontFamily="Caveat"
        position="absolute"
        transform="rotate(10deg)"
        color={color}
        {...handWritedText}
      >
        Shared files!
      </Text>
      <Icon
        as={ArrowSVG}
        color={color}
        w={70}
        position="absolute"
        {...arrowIcon}
      />
    </>
  );
};
