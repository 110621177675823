import { Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { ArrowSVG } from "../../common/icons";

export const HandwritedBuyMeACoffe = () => {
  const color = useColorModeValue("gray.600", "gray.400");

  return (
    <>
      <Text
        fontSize="xl"
        fontFamily="Caveat"
        position="absolute"
        color={color}
        left="-96px"
        top="-36px"
        transform="rotate(-8deg)"
        width="140px"
        opacity="0"
        animation="fade-in 0.36s ease-out 0.7s forwards"
      >
        {`Buy me a coffee :D`}
      </Text>
      <Icon
        as={ArrowSVG}
        color={color}
        w={70}
        position="absolute"
        left="0px"
        top="-18px"
        transform="rotate(-78deg)"
        opacity="0"
        animation="fade-in 0.36s ease-out 0.7s forwards"
      />
    </>
  );
};
